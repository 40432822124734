import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

// TODO REWRITE
import { Button, ButtonColors } from '@atoms/buttons'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useClickOutside } from '@hooks/UseClickOutside'
import { useSimpleToasts } from '@simple/toasts'

const NeedHelpWrapper = styled.div`
  display: block;
  position: relative;
  
    margin: 3.125vw 0;

    @media (min-width: 30em) {
      margin: 6.250vw 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.667vw 0 1.667vw 0;
    }

    @media (min-width: 90em) {
      margin: 24px 0 24px 0;
    }
  
  .need-help-heading {
    
    width: 100%;

    @media (min-width: 30em) {
      width: fit-content;
    }

    @media (min-width: 30.0625em) {
      width: fit-content;
    }

    @media (min-width: 90em) {
      width: fit-content;
    }
  
  }
`

const ContactActionClick = styled.div`
 &:hover {
    color : ${(props): string => props.theme.colors.green.greenVogue};
  }
`

const ContactTooltipSpacer = styled.div`
  border-style: solid;
  height: 50%;
  border-color: ${(props): string => props.theme.colors.green.watercourse};
  
    border-right-width: 0.313vw;

    @media (min-width: 30em) {
      border-right-width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      border-right-width: 0.069vw;
    }

    @media (min-width: 90em) {
      border-right-width: 1px;
    }
  
  
    width: 0.313vw;

    @media (min-width: 30em) {
      width: 0.313vw;
    }

    @media (min-width: 30.0625em) {
      width: 0.069vw;
    }

    @media (min-width: 90em) {
      width: 1px;
    }
  
  
    margin:  0 3.125vw 0 3.125vw;

    @media (min-width: 30em) {
      margin:  0 3.125vw 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin:  0 0.694vw 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin:  0 10px 0 10px;
    }
  
`

const ContactTooltipContainer = styled.div`
  position: absolute;
  z-index: 1;
  
    top: 20.000vw;

    @media (min-width: 30em) {
      top: 25.000vw;
    }

    @media (min-width: 30.0625em) {
      top: 5.556vw;
    }

    @media (min-width: 90em) {
      top: 80px;
    }
  
  
    left: -0.313vw;

    @media (min-width: 30em) {
      left: -3.125vw;
    }

    @media (min-width: 30.0625em) {
      left: -0.694vw;
    }

    @media (min-width: 90em) {
      left: -10px;
    }
  
  
    padding-top: 3.125vw;

    @media (min-width: 30em) {
      padding-top: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      padding-top: 0.972vw;
    }

    @media (min-width: 90em) {
      padding-top: 14px;
    }
  

`

const ContactTooltip = styled.div`

 font-family: 'open-sans';
  color : ${(props): string => props.theme.colors.green.greenVogue};
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-weight: 400;
  
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.833vw;
    }

    @media (min-width: 90em) {
      font-size: 12px;
    }
  
  
    gap: 1.563vw;

    @media (min-width: 30em) {
      gap: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.347vw;
    }

    @media (min-width: 90em) {
      gap: 5px;
    }
  
  
    border-radius: 0.625vw;

    @media (min-width: 30em) {
      border-radius: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.139vw;
    }

    @media (min-width: 90em) {
      border-radius: 2px;
    }
  
  
    padding: 0 3.125vw;

    @media (min-width: 30em) {
      padding: 0 9.375vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 0.972vw;
    }

    @media (min-width: 90em) {
      padding: 0 14px;
    }
  
  
    height: 10.313vw;

    @media (min-width: 30em) {
      height: 14.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.125vw;
    }

    @media (min-width: 90em) {
      height: 45px;
    }
  
  
    width: 90.625vw;

    @media (min-width: 30em) {
      width: 92.188vw;
    }

    @media (min-width: 30.0625em) {
      width: 23.958vw;
    }

    @media (min-width: 90em) {
      width: 345px;
    }
  
  background-color: ${(props): string => props.theme.colors.green.skeptic};
`

const Close = styled.div`
  cursor: pointer;
  
    height: 4.688vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.042vw;
    }

    @media (min-width: 90em) {
      height: 15px;
    }
  
  
    width: 4.688vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.042vw;
    }

    @media (min-width: 90em) {
      width: 15px;
    }
  
  
    margin: 0 0 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0 0 0 0.694vw;
    }

    @media (min-width: 90em) {
      margin: 0 0 0 10px;
    }
  
`

const IconWrappers = styled.div`
  
    height: 4.063vw;

    @media (min-width: 30em) {
      height: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 0.903vw;
    }

    @media (min-width: 90em) {
      height: 13px;
    }
  
  
    width: 4.063vw;

    @media (min-width: 30em) {
      width: 5.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.250vw;
    }

    @media (min-width: 90em) {
      width: 18px;
    }
  
`

const Arrow = styled.div`
  position:absolute;
  
    top: 0.000vw;

    @media (min-width: 30em) {
      top: 0.000vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.000vw;
    }

    @media (min-width: 90em) {
      top: 0px;
    }
  
  
    right: 6.250vw;

    @media (min-width: 30em) {
      right: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      right: 1.389vw;
    }

    @media (min-width: 90em) {
      right: 20px;
    }
  
  
    height: 4.063vw;

    @media (min-width: 30em) {
      height: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.111vw;
    }

    @media (min-width: 90em) {
      height: 16px;
    }
  
  
    width: 6.250vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
`
interface InlineSelectInputState {
  open: boolean
  activeTab: number
}

const DEFAULT_STATE = {
  open: false,
  activeTab: 3,
}

export function NeedHelp(): JSX.Element {

  const { addToast } = useSimpleToasts()
  const [state, setState] = useState<InlineSelectInputState>({ ...DEFAULT_STATE })
  const { isOutside, shouldMonitor, ref } = useClickOutside<HTMLDivElement>(true)

  const _copyContent = (text: string): void => {
    navigator.clipboard.writeText(text)
    addToast({
      message: text + ' copied to clipboard.',
      appearance: 'success',
    })
  }

  const needHelpColorObject: ButtonColors = {
    color: theme.colors.green.greenVogue,
  }

  const triggerChatButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    
    const scrollX = window.scrollX
    const scrollY = window.scrollY
    
    const chatButton = document.getElementById('fresh-chat-button')

    if (chatButton) {
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })

      chatButton.dispatchEvent(clickEvent)
      
      setTimeout(() => {
        window.scrollTo(scrollX, scrollY)
      }, 0)
    } else {
      console.error('Chat button not found')
    }
  }

  useEffect(() => {
    shouldMonitor(state.open)
  }, [state.open])

  useEffect(() => {
    if (isOutside) {
      setState((prevState) => update(prevState, {
        open: { $set: false },
      }))
    }
  }, [isOutside])

  return (
    <NeedHelpWrapper ref={ref}>
      <Button variant='action' colors={needHelpColorObject} title='Need Help?' className='need-help-heading' />
      <ButtonsContainer>
        <Button
          title='CHAT'
          variant='secondary'
          fullWidth 
          onClick={triggerChatButton}/>
      </ButtonsContainer>
    </NeedHelpWrapper>
  )

}
