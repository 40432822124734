import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { Heading, ResponsiveImage, Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api'
import { BlockTag, PageHorizontalAlignment } from '@uctypes/api/globalTypes'

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ImageContainer = styled.div`
    width: 100%;
    
    height: 31.250vw;

    @media (min-width: 30em) {
      height: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 13.889vw;
    }

    @media (min-width: 90em) {
      height: 200px;
    }
  
    flex-shrink: 0;
    
    border-top-left-radius: 1.563vw;

    @media (min-width: 30em) {
      border-top-left-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-top-left-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-top-left-radius: 5px;
    }
  
    
    border-top-right-radius: 1.563vw;

    @media (min-width: 30em) {
      border-top-right-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-top-right-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-top-right-radius: 5px;
    }
  
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    gap: 1.250vw;

    @media (min-width: 30em) {
      gap: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.278vw;
    }

    @media (min-width: 90em) {
      gap: 4px;
    }
  
    
    padding: 1.250vw 1.250vw;

    @media (min-width: 30em) {
      padding: 3.438vw 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.764vw 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 11px 20px;
    }
  
    .title {
        margin: 0;
        font-weight: 400;
        
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.389vw;
    }

    @media (min-width: 90em) {
      font-size: 20px;
    }
  
        
    padding: 0.000vw 3.750vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  

    }
`

const TagContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
    
    row-gap: 0.625vw;

    @media (min-width: 30em) {
      row-gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      row-gap: 0.417vw;
    }

    @media (min-width: 90em) {
      row-gap: 6px;
    }
  
`

const BlogTag = styled.div<{ bgColor: string }>`
    background-color: ${props => props.bgColor};
    
    padding: 0.625vw 3.750vw;

    @media (min-width: 30em) {
      padding: 1.250vw 3.750vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.278vw 0.833vw;
    }

    @media (min-width: 90em) {
      padding: 4px 12px;
    }
  
    
    border-radius: 1.563vw;

    @media (min-width: 30em) {
      border-radius: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.347vw;
    }

    @media (min-width: 90em) {
      border-radius: 5px;
    }
  
    
    .tagCopy {
        
    font-size: 3.125vw;

    @media (min-width: 30em) {
      font-size: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.694vw;
    }

    @media (min-width: 90em) {
      font-size: 10px;
    }
  
    }
`

interface PageBlockProps {
  block: PageBlockFragment
}

export function PageBlogCardBlock({ block }: PageBlockProps): JSX.Element {

  const { title, image, horizontalAlignment, foregroundColor, href, tags } = block
  const navigate = useNavigate()

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  const _handleLinkClick = () => {
    navigate(`/${href}`)
  }

  let tag: BlockTag

  return (
    <Container onClick={_handleLinkClick}>
      <LinkWrapper href={href} onClick={_handleLinkClick}>
        <ImageContainer>
          <ResponsiveImage image={image} objectFit='cover'/>
        </ImageContainer>
      </LinkWrapper>
      <ContentContainer>
        <If condition={!!tags}>
          <TagContainer>
            <For each='tag' of={tags} index='index'>
              <BlogTag key={uuidv4()} bgColor={tag.backgroundColor}>
                <Title variant='t10' color={tag.foregroundColor} className='tagCopy'>
                  {tag.title}
                </Title>
              </BlogTag>
            </For>
          </TagContainer>
        </If>
        <Heading variant='h4' align={alignment} color={foregroundColor} className='title'>{title}</Heading>
      </ContentContainer>
    </Container>
  )
}
