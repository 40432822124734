import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { SmallLoader } from '@atoms/notifications'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PeachPaymentTokenFragment } from '@hooks/api/index'
import { PeachPaymentsCreditCardTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
  
    border-radius: 0;

    @media (min-width: 30em) {
      border-radius: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.694vw;
    }

    @media (min-width: 90em) {
      border-radius: 10px;
    }
  
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 18.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.167vw;
    }

    @media (min-width: 90em) {
      width: 60px;
    }
  
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  
    width: 5.000vw;

    @media (min-width: 30em) {
      width: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.389vw;
    }

    @media (min-width: 90em) {
      width: 20px;
    }
  
  
    height: 5.000vw;

    @media (min-width: 30em) {
      height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.389vw;
    }

    @media (min-width: 90em) {
      height: 20px;
    }
  
  
    border-radius: 3.125vw;

    @media (min-width: 30em) {
      border-radius: 4.063vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 0.903vw;
    }

    @media (min-width: 90em) {
      border-radius: 13px;
    }
  
  ${(props): string => {
    if (props.active) {
      return `
        
    border-width: 1.250vw;

    @media (min-width: 30em) {
      border-width: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.347vw;
    }

    @media (min-width: 90em) {
      border-width: 5px;
    }
  
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    
    border-width: 0.313vw;

    @media (min-width: 30em) {
      border-width: 0.625vw;
    }

    @media (min-width: 30.0625em) {
      border-width: 0.139vw;
    }

    @media (min-width: 90em) {
      border-width: 2px;
    }
  
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const LogoContainer = styled.div`
  flex-shrink: 0;
  
    width: 10.000vw;

    @media (min-width: 30em) {
      width: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.444vw;
    }

    @media (min-width: 90em) {
      width: 64px;
    }
  
  
    height: 10.000vw;

    @media (min-width: 30em) {
      height: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.778vw;
    }

    @media (min-width: 90em) {
      height: 40px;
    }
  
  
    margin-right: 1.875vw;

    @media (min-width: 30em) {
      margin-right: initial;
    }

    @media (min-width: 30.0625em) {
      margin-right: initial;
    }

    @media (min-width: 90em) {
      margin-right: initial;
    }
  
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  
  justify-content: flex-start;
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: row;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
    }

    @media (min-width: 90em) {
      flex-direction: row;
    }
  
  
    gap: 0.625vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.694vw;
    }

    @media (min-width: 90em) {
      gap: 10px;
    }
  
  
    margin-left: 1.875vw;

    @media (min-width: 30em) {
      margin-left: 11.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-left: 2.500vw;
    }

    @media (min-width: 90em) {
      margin-left: 36px;
    }
  
`

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
    width: initial;

    @media (min-width: 30em) {
      width: 62.500vw;
    }

    @media (min-width: 30.0625em) {
      width: 13.889vw;
    }

    @media (min-width: 90em) {
      width: 200px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  
  .input {
    
    width: 21.875vw;

    @media (min-width: 30em) {
      width: initial;
    }

    @media (min-width: 30.0625em) {
      width: initial;
    }

    @media (min-width: 90em) {
      width: initial;
    }
  
  }
`

const LoadingContainer = styled.div`
  
    width: 15.625vw;

    @media (min-width: 30em) {
      width: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      width: 3.472vw;
    }

    @media (min-width: 90em) {
      width: 50px;
    }
  
  
    height: 15.625vw;

    @media (min-width: 30em) {
      height: 15.625vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.472vw;
    }

    @media (min-width: 90em) {
      height: 50px;
    }
  
`

export interface CreditCardRowProps {
  creditCard: PeachPaymentTokenFragment
  selected: boolean
  loading?: boolean
  onSelect: () => void
  onRemove: () => void
}

export function CreditCardRow({ selected, creditCard, loading, onSelect, onRemove }: CreditCardRowProps): JSX.Element {

  const theme = useTheme()

  let logo!: LocalIconEnums

  switch (creditCard.cardBrand) {
    case PeachPaymentsCreditCardTypeEnum.AMERICAN_EXPRESS:
      logo = LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR
      break
    case PeachPaymentsCreditCardTypeEnum.MASTERCARD:
    case PeachPaymentsCreditCardTypeEnum.MASTER:
      logo = LocalIconEnums.PAYMENT_MASTER_CARD_COLOR
      break
    default:
      logo = LocalIconEnums.PAYMENT_VISA_COLOR
      break
  }

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>
      <LogoContainer>
        <Icon icon={logo} />
      </LogoContainer>
      <TextContainer onClick={onSelect}>
        <Paragraph>
          {`*** ${creditCard.last4Digits}`}
        </Paragraph>
        <Paragraph color={theme.colors.grey.gunSmoke}>
          {`Expires: ${creditCard.expiryYear}/${creditCard.expiryMonth}`}
        </Paragraph>
      </TextContainer>
      <ControlsContainer>
        <Choose>
          <When condition={loading}>
            <LoadingContainer>
              <SmallLoader color={theme.colors.green.greenVogue} />
            </LoadingContainer>
          </When>
          <Otherwise>
            <If condition={!selected}>
              <Button variant='text' title='Remove' onClick={onRemove} />
            </If>
          </Otherwise>
        </Choose>
      </ControlsContainer>
    </Container>
  )

}
