import React, { useEffect } from 'react'

import { useLoading } from '@contexts/LoadingProvider'
import { CmsPageFragment } from '@hooks/api/index'
import { Page } from '@organisms/index'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

import { NotFound } from './NotFound'

export interface CmsPageProps {
  cmsPage?: CmsPageFragment
  loading: boolean
}

export const CmsPage = React.memo(
  function CmsPage({ cmsPage, loading }: CmsPageProps): JSX.Element {

    const { setLoading } = useLoading()

    useEffect(() => {
      setLoading(loading)
    }, [loading])

    const seo: SearchEngineOptimizationParams = {
      name: cmsPage?.title,
      title: cmsPage?.title,
      meta: [], // <---- TODO
    }

    return (
      <>
        <SearchEngineOptimization seo={seo} />
        <If condition={!loading}>
          <Choose>
            <When condition={!!cmsPage?.pageContent}>
              <Page page={cmsPage?.pageContent} />
            </When>
            <Otherwise>
              <NotFound />
            </Otherwise>
          </Choose>
        </If>
      </>
    )

  },
)
