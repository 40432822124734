import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue, theme } from '@components/Theme'
import { SpecialsChildCategoryFragment, useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { SpecialCard } from '@molecules/store/SpecialCard'

const Container = styled.div`
  width: 100%;
  position: relative;
  
    overflow: hidden;

    @media (min-width: 30em) {
      overflow: initial;
    }

    @media (min-width: 30.0625em) {
      overflow: initial;
    }

    @media (min-width: 90em) {
      overflow: initial;
    }
  
  
    padding: 3.750vw 0 3.750vw 3.750vw;

    @media (min-width: 30em) {
      padding: 0 ;
    }

    @media (min-width: 30.0625em) {
      padding: 0;
    }

    @media (min-width: 90em) {
      padding: 0;
    }
  
  
    margin-top: initial;

    @media (min-width: 30em) {
      margin-top: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-top: 8px;
    }
  
  
  .mobile-all {
    
    margin: 5.000vw 0 0;

    @media (min-width: 30em) {
      margin: 5.000vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0 0;
    }

    @media (min-width: 90em) {
      margin: 16px 0 0;
    }
  
    
    width: CALC(100% - 5.000vw);

    @media (min-width: 30em) {
      width: CALC(100% - 5.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% - 1.111vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% - 16px);
    }
  
  }
`

const Spacer = styled.div`
  position: relative;
`

const Embla = styled.div`
  position: relative;
  
    overflow: visible;

    @media (min-width: 30em) {
      overflow: hidden;
    }

    @media (min-width: 30.0625em) {
      overflow: hidden;
    }

    @media (min-width: 90em) {
      overflow: hidden;
    }
  
  width: 100%;
`

const EmblaContainer = styled.div<{ infiniteScroll: boolean }>`
  position: relative;
  display: flex;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.389vw;
    }

    @media (min-width: 90em) {
      gap: 20px;
    }
  

${props => props.infiniteScroll && `
    &::before {
      content: '';
      
    width: 1.250vw;

    @media (min-width: 30em) {
      width: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      width: 0.417vw;
    }

    @media (min-width: 90em) {
      width: 6px;
    }
  
      flex-shrink: 0;
    }
  `}
`

const EmblaSlide = styled.div`
  user-select: none;
  
    flex: 0 0 49.063vw;

    @media (min-width: 30em) {
      flex: 0 0 49.063vw;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 15.139vw;
    }

    @media (min-width: 90em) {
      flex: 0 0 218px;
    }
  
`

const LeftControl = styled.div<{ visible: boolean }>`
  
    display: none;

    @media (min-width: 30em) {
      display: block;
    }

    @media (min-width: 30.0625em) {
      display: block;
    }

    @media (min-width: 90em) {
      display: block;
    }
  
  user-select: none;
  position: absolute;
  cursor: pointer;
  opacity: ${(props): number => props.visible ? 1 : 0};
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.2)};
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: CALC(50% - 4.688vw);

    @media (min-width: 30em) {
      top: CALC(50% - 4.688vw);
    }

    @media (min-width: 30.0625em) {
      top: CALC(50% - 1.042vw);
    }

    @media (min-width: 90em) {
      top: CALC(50% - 15px);
    }
  
  left: 0;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.4)};
  }
 
`

const RightControl = styled.div<{ visible: boolean }>`
  
    display: none;

    @media (min-width: 30em) {
      display: block;
    }

    @media (min-width: 30.0625em) {
      display: block;
    }

    @media (min-width: 90em) {
      display: block;
    }
  
  user-select: none;
  position: absolute;
  cursor: pointer;
  opacity: ${(props): number => props.visible ? 1 : 0};
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.2)};
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: CALC(50% - 4.688vw);

    @media (min-width: 30em) {
      top: CALC(50% - 4.688vw);
    }

    @media (min-width: 30.0625em) {
      top: CALC(50% - 1.042vw);
    }

    @media (min-width: 90em) {
      top: CALC(50% - 15px);
    }
  
  right: 0;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.4)};
  }
`

export interface DealsSliderProps {
  title?: string
  deals: SpecialsChildCategoryFragment[]
  total: number
  loading?: boolean
  infiniteScroll?: boolean
  lessThanSixItems?: boolean
}

interface DealsSliderState {
  previousDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  disaplyButtons: boolean
}

const DEFAULT_STATE: DealsSliderState = {
  previousDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  disaplyButtons: false,
}

export const DealsSlider = React.memo(
  function DealsSlider({
    deals,
    loading,
    infiniteScroll = false,
    lessThanSixItems = false,
  }: DealsSliderProps): JSX.Element {

    const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
    const [state, setState] = useState<DealsSliderState>({ ...DEFAULT_STATE })
    const [emblaRef, emblaApi] = useEmblaCarousel({
      align: 'start',
      dragFree: !!(appData.app.isNativeApp),
      slidesToScroll: 1,
      containScroll: 'trimSnaps',
      loop: !!infiniteScroll,
    })

    const _handleGoLeft = (): void => {
      if (emblaApi.canScrollPrev()) {
        emblaApi.scrollPrev()
      }
    }

    const _handleGoRight = (): void => {
      if (emblaApi.canScrollNext()) {
        emblaApi.scrollNext()
      } else {
        emblaApi.scrollTo(0)
      }
    }

    useEffect(() => {
      if (emblaApi) {
        emblaApi.on('select', () => {
          const previousDisabled = !emblaApi.canScrollPrev()
          const nextDisabled = false
          const currentIndex = emblaApi.selectedScrollSnap()
          setState((prevState) => update(prevState, {
            previousDisabled: { $set: previousDisabled },
            nextDisabled: { $set: nextDisabled },
            currentIndex: { $set: currentIndex },
          }))
        })
      }
    }, [emblaApi])

    let deal: SpecialsChildCategoryFragment

    const isLessThanSixItems = deals.length <= 6

    return (
      <Container>
        <Spacer>
          <Embla ref={emblaRef}>
            <EmblaContainer infiniteScroll={infiniteScroll}>
              <For each='deal' of={deals}>
                <EmblaSlide
                  key={deal.id}>
                  <SpecialCard category={deal} />
                </EmblaSlide>
              </For>
            </EmblaContainer>
          </Embla>
          <If condition={!(lessThanSixItems && isLessThanSixItems)}>
            <LeftControl onClick={_handleGoLeft} visible={!state.previousDisabled}>
              <Icon
                icon={LocalIconEnums.CHEVRON_LEFT}
                color={theme.colors.green.bottleGreen} />
            </LeftControl>
            <RightControl onClick={_handleGoRight} visible={true}>
              <Icon
                icon={LocalIconEnums.CHEVRON_RIGHT}
                color={theme.colors.green.bottleGreen} />
            </RightControl>
          </If>
        </Spacer>
      </Container>
    )

  },
)
