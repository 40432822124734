import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMyShopSectionFragment, useCustomerQuery, useGetAppQuery, useGetFrequentlyBoughtProductsQuery } from '@hooks/api'
import { PageBanner } from '@molecules/content'
import { ProductSlider } from '@organisms/shop'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    padding: 10.000vw;

    @media (min-width: 30em) {
      padding: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 32px;
    }
  
  
    border: 0.313vw solid #000;

    @media (min-width: 30em) {
      border: 0.313vw solid #000;
    }

    @media (min-width: 30.0625em) {
      border: 0.069vw solid #000;
    }

    @media (min-width: 90em) {
      border: 1px solid #000;
    }
  
`

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  
    gap: 0;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  background-color: ${(props): string => props.backgroundColor};
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  

  .block-title {
    
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

  .block-paragraph {
    
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

`

const TopContainer = styled.div`
  display: flex;
  align-items: flex-end;
  
    justify-content: center;

    @media (min-width: 30em) {
      justify-content: space-between;
    }

    @media (min-width: 30.0625em) {
      justify-content: space-between;
    }

    @media (min-width: 90em) {
      justify-content: space-between;
    }
  
`

const TitleContainer = styled.div`
  .sub-title {
    
    margin-top: 3.750vw;

    @media (min-width: 30em) {
      margin-top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-top: 12px;
    }
  
  }
`
export interface PageMyDiscountsSectionProps {
  section: PageMyShopSectionFragment
  showPlaceholder?: boolean
}

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  PRODUCTS = 'PRODUCTS',
}

interface PageMyShopSectionState {
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: PageMyShopSectionState = {
  displayType: DisplayTypeEnum.LOADING,
}

export function PageMyShopSection({ section, showPlaceholder = false }: PageMyDiscountsSectionProps): JSX.Element {

  const [state, setState] = useState<PageMyShopSectionState>({ ...DEFAULT_STATE })
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: myShopData, loading: myShopLoading } = useGetFrequentlyBoughtProductsQuery()
  const navigate = useNavigate()

  const _handleViewAll = () => {
    navigate('/my-ftn-shop/my-shop')
  }

  useEffect(() => {
    if (customerLoading) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.LOADING,
        },
      }))
    } else if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    } else {
      if (myShopLoading) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.LOADING,
          },
        }))
      } else if (myShopData?.frequentlyBoughtProducts?.items?.length) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.PRODUCTS,
          },
        }))
      } else {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.EMPTY,
          },
        }))
      }
    }
  }, [customerData?.currentCustomer, myShopData?.frequentlyBoughtProducts?.items])

  const titleAlignment = section.titleAlignment === 'LEFT'
    ? 'left'
    : section.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'

  if (state.displayType === DisplayTypeEnum.EMPTY && !showPlaceholder && !section.placeholderBanner) {
    return null
  }

  let products = myShopData?.frequentlyBoughtProducts?.items?.map((itm) => itm.product) || []
  const isLessThanSixItems = myShopData?.frequentlyBoughtProducts?.items?.length <= 6

  if (products.length > 10) {
    products = products?.filter((product) => product.stockInfo?.qty !== 0)
  } else {
    products = products?.sort((a, b) => {
      const qtyA = a.stockInfo?.qty ?? 0
      const qtyB = b.stockInfo?.qty ?? 0

      if (qtyA === 0 && qtyB !== 0) return 1
      if (qtyA !== 0 && qtyB === 0) return -1
      return 0
    })
  }

  return (
    <Container backgroundColor={section?.backgroundColor}>
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.LOADING}>
          <Loader />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && !!section.placeholderBanner}>
          <PageBanner pageBanner={section.placeholderBanner} />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && showPlaceholder}>
          <Placeholder>
            <Title variant='t3'>My Shop Placeholder</Title>
            <Paragraph>There is no preview content for this section while the user is logged out or has no previously bought items</Paragraph>
          </Placeholder>
        </When>
        <Otherwise>
          <TopContainer>
            <TitleContainer>
              <Heading
                variant={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'h3' : 'h1'}
                className='block-title'
                color={section.foregroundColor}
                align={titleAlignment}>
                Previously Bought
              </Heading>
            </TitleContainer>
            <If condition={!isLessThanSixItems}>
              <DeviceContainer desktop tablet>
                <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium'/>
              </DeviceContainer>
            </If>
          </TopContainer>
          <ProductSlider
            showCount={false}
            total={products?.length}
            products={products}
            lessThanSixItems={true}
            leftPadding={false}/>
          <If condition={!isLessThanSixItems}>
            <DeviceContainer mobile>
              <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium' fullWidth/>
            </DeviceContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )
}
