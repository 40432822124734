import { useEffect, useRef, useState } from 'react'

import { useLocation } from 'react-router-dom'

interface ScrollPosition {
  [path: string]: number
}

export function ScrollPositionManager({ isLoading }: { isLoading: boolean }): null {
  const location = useLocation()
  const scrollPositions = useRef<ScrollPosition>({})
  const currentPath = location.pathname
  const previousPath = useRef<string>(currentPath)
  const restoreAttempted = useRef<boolean>(false)
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)
  const scrollEndTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleScroll = () => {
    if (scrollEndTimeout.current) {
      clearTimeout(scrollEndTimeout.current)
    }

    scrollEndTimeout.current = setTimeout(() => {
      if (!isLoading && window) {
        const currentScroll = window.scrollY
        scrollPositions.current = {
          ...scrollPositions.current,
          [currentPath]: currentScroll,
        }
      }
    }, 200)
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', handleScroll)
        if (scrollEndTimeout.current) {
          clearTimeout(scrollEndTimeout.current)
        }
      }
    }
  }, [currentPath, isLoading])

  useEffect(() => {
    if (isInitialLoad) {
      const timer = setTimeout(() => {
        setIsInitialLoad(false)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [isInitialLoad])

  useEffect(() => {
    try {
      if (isInitialLoad) {
        return
      }

      if (previousPath.current !== currentPath) {
        previousPath.current = currentPath
        restoreAttempted.current = false
      }
    } catch (error) {
      console.error('Error saving scroll position', error)
    }
  }, [currentPath, isInitialLoad])

  useEffect(() => {
    try {
      if (isLoading) {
        restoreAttempted.current = false
      }
    } catch (error) {
      console.error('Error resetting restore attempt flag', error)
    }
  }, [isLoading])

  useEffect(() => {
    try {
      if (isInitialLoad || isLoading || restoreAttempted.current) {
        return
      }

      restoreAttempted.current = true
      const savedPosition = scrollPositions.current[currentPath]

      if (savedPosition !== undefined) {
        const checkInterval = 100
        let attempts = 0
        const maxAttempts = 10

        const tryScroll = () => {
          if (window) {
            window.scrollTo({
              top: savedPosition,
              behavior: 'smooth',
            })

            attempts++

            if (Math.abs(window.scrollY - savedPosition) > 10 && attempts < maxAttempts) {
              setTimeout(tryScroll, checkInterval)
            }
          }
        }

        setTimeout(tryScroll, 300)
      }
    } catch (error) {
      console.error('Error restoring scroll position', error)
    }
  }, [currentPath, isLoading, isInitialLoad])

  return null
}
