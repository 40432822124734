import React from 'react'

import { ModalProvider } from 'styled-react-modal'

import { useLoading } from '@contexts/LoadingProvider'
import { SiteHelper } from '@lib/SiteHelper'
import { Routes } from '@routes/Routes'
import { SimpleToastsProvider } from '@simple/toasts'
import { ResizeContainer } from '@utility/index'
import { ScrollPositionManager } from '@utility/ScrollPositionManager'

import { GlobalStyle } from './GlobalStyle'
import { StoreConfig } from './StoreConfig'

export function AppContent(): JSX.Element {

  const { isLoading } = useLoading()

  return (
    <>
      <GlobalStyle />
      <StoreConfig />
      <SimpleToastsProvider config={SiteHelper.getSimpleToastConfig()}>
        <ModalProvider>
          <ScrollPositionManager isLoading={isLoading} />
          <ResizeContainer>
            <Routes />
          </ResizeContainer>
        </ModalProvider>
      </SimpleToastsProvider>
    </>
  )
}
