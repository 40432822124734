
import { Icon, LocalIconEnums } from '@atoms/index'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import React from 'react'
import styled, { useTheme } from 'styled-components'

const Container = styled.div`
    background-color: ${(props): string => props.theme.colors.green.swansDown};
    
    height: 17.188vw;

    @media (min-width: 30em) {
      height: 17.188vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.819vw;
    }

    @media (min-width: 90em) {
      height: 55px;
    }
  
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-top-left-radius: 7.187vw;

    @media (min-width: 30em) {
      border-top-left-radius: 7.187vw;
    }

    @media (min-width: 30.0625em) {
      border-top-left-radius: 1.597vw;
    }

    @media (min-width: 90em) {
      border-top-left-radius: 23px;
    }
  
    
    border-bottom-right-radius: 7.187vw;

    @media (min-width: 30em) {
      border-bottom-right-radius: 7.187vw;
    }

    @media (min-width: 30.0625em) {
      border-bottom-right-radius: 1.597vw;
    }

    @media (min-width: 90em) {
      border-bottom-right-radius: 23px;
    }
  
    position: relative;

    .copy {
        font-weight: 700;
    }
`   

const IconContainer = styled.div`
    
    height: 20.000vw;

    @media (min-width: 30em) {
      height: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 4.444vw;
    }

    @media (min-width: 90em) {
      height: 64px;
    }
  
    
    width: 20.000vw;

    @media (min-width: 30em) {
      width: 20.000vw;
    }

    @media (min-width: 30.0625em) {
      width: 4.444vw;
    }

    @media (min-width: 90em) {
      width: 64px;
    }
  
    position: absolute;
    
    right: -6.250vw;

    @media (min-width: 30em) {
      right: -6.250vw;
    }

    @media (min-width: 30.0625em) {
      right: -1.389vw;
    }

    @media (min-width: 90em) {
      right: -20px;
    }
  
    
    top: 3.125vw;

    @media (min-width: 30em) {
      top: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      top: 0.694vw;
    }

    @media (min-width: 90em) {
      top: 10px;
    }
  
`

export function ChatButton(): JSX.Element {

    const theme = useTheme()

    return (
        <>
            <Container id='fresh-chat-button'>
                <Paragraph 
                    variant='p2' 
                    className='copy' 
                    color={theme.colors.green.watercourse}>
                    
                        Chat with us
                </Paragraph>
                <IconContainer>
                    <Icon icon={LocalIconEnums.CHAT}/>
                </IconContainer>
            </Container>
        </>
    )   
}