import { title } from 'process'

import React, { } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Heading, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageDealsSliderSectionFragment, SpecialsChildCategoryFragment, useGetAppQuery } from '@hooks/api/index'
import { DealsSlider } from '@organisms/shop/DealsSlider'
import { DeviceTypeEnum } from '@simple/admin-ui/build/types/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
    gap: initial;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  width: 100%;

  .block-title {
    
    margin: 0 3.750vw;

    @media (min-width: 30em) {
      margin: 0 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

  .block-paragraph {
    
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

  .mobile-all {
    margin: 0 auto;
    
    margin: 5.000vw;

    @media (min-width: 30em) {
      margin: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 16px;
    }
  
    
    width: CALC(100% - 10.000vw);

    @media (min-width: 30em) {
      width: CALC(100% - 10.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% - 2.222vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% - 32px);
    }
  
  }

`

const Header = styled.div`
 
  display: flex;
  align-items: center;
  
    justify-content: flex-start;

    @media (min-width: 30em) {
      justify-content: space-between;
    }

    @media (min-width: 30.0625em) {
      justify-content: space-between;
    }

    @media (min-width: 90em) {
      justify-content: space-between;
    }
  
  
    max-height: 17.500vw;

    @media (min-width: 30em) {
      max-height: 17.500vw;
    }

    @media (min-width: 30.0625em) {
      max-height: 3.889vw;
    }

    @media (min-width: 90em) {
      max-height: 56px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  
    margin: 3.750vw 0 1.875vw;

    @media (min-width: 30em) {
      margin: 3.750vw 0 1.875vw;
    }

    @media (min-width: 30.0625em) {
      margin: 2.222vw 0 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 32px 0 16px;
    }
  

  .seperator {
    flex-grow: 1;
    position: revert;
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }
  .filter-title {
    margin: 0;
    
    flex-shrink: initial;

    @media (min-width: 30em) {
      flex-shrink: initial;
    }

    @media (min-width: 30.0625em) {
      flex-shrink: 0;
    }

    @media (min-width: 90em) {
      flex-shrink: 0;
    }
  
  }
  .see-all {
    flex-shrink: 0;
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }
  
`

export interface PageDealsSliderSectionProps {
  section: PageDealsSliderSectionFragment
}

export function PageDealsSliderSection({ section }: PageDealsSliderSectionProps): JSX.Element {

  const navigate = useNavigate()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const _handleViewAll = () => {
    // navigate(section.category.canonicalUrl)
  }

  const titleAlignment = section.titleAlignment === 'LEFT'
    ? 'left'
    : section.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'
  const deals = section?.deals || []
  const totalCount = section?.deals?.length || 0

  return (
    <Container>
      <Header>
        <Title
          variant={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 't3' : 't1'}
          className='block-title'
          align={titleAlignment}>
          {section.title}
        </Title>
        <Button
          variant='primary'
          title='SEE ALL'
          size='medium'
          className='see-all'
          onClick={_handleViewAll} />
      </Header>
      <DealsSlider
        title={section.title}
        total={totalCount}
        deals={deals as SpecialsChildCategoryFragment[]} />
      <DeviceContainer mobile tablet>
        <Button
          variant='ghost'
          title='SEE ALL'
          size='medium'
          className='mobile-all'
          fullWidth
          onClick={_handleViewAll} />
      </DeviceContainer>
    </Container>
  )

}
