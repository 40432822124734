import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMyDiscountsSectionFragment, PersonalDiscountFragment, ProductListFragment, useCustomerQuery, useGetAppQuery, usePersonalDiscountsLazyQuery, usePersonalDiscountsQuery } from '@hooks/api'
import { PageBanner } from '@molecules/content/PageBanner'
import { ProductSlider } from '@organisms/shop/ProductSlider'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    padding: 10.000vw;

    @media (min-width: 30em) {
      padding: 10.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 2.222vw;
    }

    @media (min-width: 90em) {
      padding: 32px;
    }
  
`

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  
  
    padding: 3.750vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  background-color: ${(props): string => props.backgroundColor};


  .block-title {
    
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

  .block-paragraph {
    
    margin: 0 3.125vw;

    @media (min-width: 30em) {
      margin: 0 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin: 0;
    }

    @media (min-width: 90em) {
      margin: 0;
    }
  
  }

  .sliderCopy{
    font-weight: 400;
  }

`

const HeadingContainer = styled.div`
  display: flex;
  
    justify-content: center;

    @media (min-width: 30em) {
      justify-content: space-between;
    }

    @media (min-width: 30.0625em) {
      justify-content: space-between;
    }

    @media (min-width: 90em) {
      justify-content: space-between;
    }
  
`

const CustomerPromoContainer = styled.div`
  ${(props): string => ResponsivePXValue('background-color', { mobile: props.theme.colors.white.pureWhite })}
  
    padding: 2.500vw 5.000vw;

    @media (min-width: 30em) {
      padding: initial;
    }

    @media (min-width: 30.0625em) {
      padding: initial;
    }

    @media (min-width: 90em) {
      padding: initial;
    }
  
  
    display: block;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  
    flex-direction: initial;

    @media (min-width: 30em) {
      flex-direction: column;
    }

    @media (min-width: 30.0625em) {
      flex-direction: column;
    }

    @media (min-width: 90em) {
      flex-direction: column;
    }
  
  
    gap: initial;

    @media (min-width: 30em) {
      gap: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.833vw;
    }

    @media (min-width: 90em) {
      gap: 12px;
    }
  

`

const PromoDetails = styled.div`
  display: flex;
  
    align-items: center;

    @media (min-width: 30em) {
      align-items: flex-end;
    }

    @media (min-width: 30.0625em) {
      align-items: flex-end;
    }

    @media (min-width: 90em) {
      align-items: flex-end;
    }
  
  flex-direction: row;

  .seeAllBtn {
    width: 100%;
    white-space: nowrap;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const Details = styled.div`
  width: 100%;
  display: flex;
  
    align-items: flex-start;

    @media (min-width: 30em) {
      align-items: center;
    }

    @media (min-width: 30.0625em) {
      align-items: center;
    }

    @media (min-width: 90em) {
      align-items: center;
    }
  
  
    flex-direction: column;

    @media (min-width: 30em) {
      flex-direction: initial;
    }

    @media (min-width: 30.0625em) {
      flex-direction: initial;
    }

    @media (min-width: 90em) {
      flex-direction: initial;
    }
  
  
    gap: 3.750vw;

    @media (min-width: 30em) {
      gap: initial;
    }

    @media (min-width: 30.0625em) {
      gap: initial;
    }

    @media (min-width: 90em) {
      gap: initial;
    }
  
  
    margin-top: 2.500vw;

    @media (min-width: 30em) {
      margin-top: initial;
    }

    @media (min-width: 30.0625em) {
      margin-top: initial;
    }

    @media (min-width: 90em) {
      margin-top: initial;
    }
  
`

const PromoCodeChip = styled.span`
  border: 2px solid ${(props): string => props.theme.colors.green.viridian}; 
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  color: ${(props): string => props.theme.colors.green.viridian};
  
    font-size: 3.750vw;

    @media (min-width: 30em) {
      font-size: 4.375vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 0.972vw;
    }

    @media (min-width: 90em) {
      font-size: 14px;
    }
  
  
    line-height: 5.625vw;

    @media (min-width: 30em) {
      line-height: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 1.528vw;
    }

    @media (min-width: 90em) {
      line-height: 22px;
    }
    
  border-radius: 16px; 
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    margin-left: initial;

    @media (min-width: 30em) {
      margin-left: initial;
    }

    @media (min-width: 30.0625em) {
      margin-left: 1.667vw;
    }

    @media (min-width: 90em) {
      margin-left: 24px;
    }
  
  cursor: pointer;
`

export interface PageMyDiscountsSectionProps {
  section: PageMyDiscountsSectionFragment
  showPlaceholder?: boolean
}

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  DISCOUNTS = 'DISCOUNTS',
}

interface PageMyDiscountsSectionState {
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: PageMyDiscountsSectionState = {
  displayType: DisplayTypeEnum.LOADING,
}

export function PageMyDiscountsSection({ section, showPlaceholder = false }: PageMyDiscountsSectionProps): JSX.Element {

  const theme = useTheme()
  const [state, setState] = useState<PageMyDiscountsSectionState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: discountsData, loading: discountsLoading } = usePersonalDiscountsQuery()
  const navigate = useNavigate()

  const _handleViewAll = (): void => {
    navigate('/my-ftn-shop/my-discounts')
  }

  const _handleCopyCode = (code: string): void => {
    navigator.clipboard.writeText(code)
    addToast({
      message: 'Your personal discount code has been copied to your clipboard.',
      appearance: 'success',
    })
  }

  useEffect(() => {
    if (customerLoading) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.LOADING,
        },
      }))
    } else if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.EMPTY,
        },
      }))
    } else {
      if (discountsLoading) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.LOADING,
          },
        }))
      } else if (discountsData?.personalDiscounts?.length) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.DISCOUNTS,
          },
        }))
      } else {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.EMPTY,
          },
        }))
      }
    }
  }, [customerData?.currentCustomer, discountsData?.personalDiscounts])

  const { MOBILE } = DeviceTypeEnum
  const titleAlignment = section.titleAlignment === 'LEFT'
    ? 'left'
    : section.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'

  let discount: PersonalDiscountFragment

  if (state.displayType === DisplayTypeEnum.EMPTY && !showPlaceholder && !section.placeholderBanner) {
    return null
  }

  const isLessThanSixItems = discountsData?.personalDiscounts?.length || 0 <= 6

  return (
    <Container backgroundColor={section?.backgroundColor}>
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.LOADING}>
          <Loader />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && !!section.placeholderBanner}>
          <PageBanner pageBanner={section.placeholderBanner} />
        </When>
        <When condition={state.displayType === DisplayTypeEnum.EMPTY && showPlaceholder}>
          <Placeholder>
            <Title variant='t3'>My Discounts Placeholder</Title>
            <Paragraph>There is no preview content for this section while the user is logged out or has no personal discounts</Paragraph>
          </Placeholder>
        </When>
        <Otherwise>
          <HeadingContainer>
            <Heading
              variant={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'h3' : 'h1'}
              className='block-title'
              align={titleAlignment}>
                Discounts for {customerData?.currentCustomer?.firstname}
            </Heading>
          </HeadingContainer>
          <For each='discount' of={discountsData?.personalDiscounts || []}>
            <CustomerPromoContainer>
              <Heading variant='h6' >Get {discount?.percentage}% off</Heading>
              <PromoDetails>
                <Details>
                  <Heading variant='h6' className='sliderCopy'>
                          To activate your discounts, copy this unique promo code and paste it into the promo code box at checkout: </Heading>
                  <PromoCodeChip onClick={() => _handleCopyCode(discount?.code)}>
                    <Heading
                      variant='h6'
                      className='sliderCopy'
                      color={theme.colors.green.viridian}>
                      {discount?.code}
                    </Heading>
                  </PromoCodeChip>
                </Details>
                <If condition={!isLessThanSixItems}>
                  <DeviceContainer tablet desktop>
                    <ButtonContainer>
                      <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium'/>
                    </ButtonContainer>
                  </DeviceContainer>
                </If>
              </PromoDetails>
            </CustomerPromoContainer>
            <If condition={discount?.products?.items?.length > 0}>
              <ProductSlider
                showCount={false}
                total={discount?.products?.items?.length}
                products={discount?.products?.items as unknown as ProductListFragment[]}
                productsDiscount={discount?.percentage}
                leftPadding={false}
                lessThanSixItems={true} />
            </If>
          </For>
          <If condition={!isLessThanSixItems}>
            <DeviceContainer mobile>
              <Button className='seeAllBtn' onClick={_handleViewAll} title='Shop All' variant='primary' size='medium' fullWidth/>
            </DeviceContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )
}
