import React from 'react'

import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { ThemeProvider } from 'styled-components'

import { theme } from '@components/Theme'
import { ConfigProvider } from '@contexts/ConfigProvider'
import { EventProvider } from '@contexts/GTMProvider'
import { LoadingProvider } from '@contexts/LoadingProvider'
import { Config } from '@lib/Config'
import { UserEvents } from '@lib/UserEvents'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { AppContent } from './AppContent'

interface AppProps {
  client: ApolloClient<NormalizedCacheObject>
  config: Config
  events: UserEvents
}

export function App({ client, config, events }: AppProps): JSX.Element {

  return (
    <ConfigProvider config={config}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <EventProvider events={events}>
            <GoogleOAuthProvider clientId={config.getGoogleAuthKey()}>
              <LoadingProvider>
                <AppContent />
              </LoadingProvider>
            </GoogleOAuthProvider>
          </EventProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ConfigProvider>
  )

}
