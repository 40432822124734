import React, { useState, useEffect, useRef } from 'react'

import qs from 'qs'
import update from 'react-addons-update'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { CartFragment, PeachPayments3DSecurePreconditionFragment, ThreeDSecureFragment, useAuthenticate3DSecureMutation, useGetPeachPaymentsPaymentStatusLazyQuery, useCustomerQuery, CheckoutFragment } from '@hooks/api/index'
import { PeachPayments3DsInput, PeachPaymentsCardInput, PeachPaymentsCreditCardTypeEnum, PeachPaymentsSimpleStatus } from '@uctypes/api/globalTypes'

const FORM_SUBMIT_TIMEOUT = 100
const POLLING_INTERVAL = 3000

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`
const Content = styled.div`

`

const IframeContainer = styled.div`
  width: 100%;
  
    height: 125.000vw;

    @media (min-width: 30em) {
      height: 125.000vw;
    }

    @media (min-width: 30.0625em) {
      height: 41.667vw;
    }

    @media (min-width: 90em) {
      height: 600px;
    }
  
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    gap: 6.250vw;

    @media (min-width: 30em) {
      gap: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.389vw;
    }

    @media (min-width: 90em) {
      gap: 20px;
    }
  
  
    padding: 6.250vw;

    @media (min-width: 30em) {
      padding: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.389vw;
    }

    @media (min-width: 90em) {
      padding: 20px;
    }
  
`

const getRedirect = (): string => {

  let url = ''
  if (typeof window !== 'undefined') {
    url = `${window.location.protocol}//${window.location.hostname}`
    if (window.location.port) {
      url += `:${window.location.port}`
    }
  }
  url += '/payments/peach/response'
  return url
}

const getCurrentUrl = (): string => {
  if (typeof window !== 'undefined') {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
  }
  return ''
}

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  REDIRECT = 'REDIRECT',
  PRECONDITION = 'PRECONDITION',
  ERROR = 'ERROR',
}

export interface ThreeDSecureProps {
  checkout: CheckoutFragment
  cart: CartFragment
}

interface ThreeDSecureState {
  displayType: DisplayTypeEnum
  redirect: string
  auth: ThreeDSecureFragment | null
  preConditionsCompleted: number | null
}

const DEFAULT_STATE: ThreeDSecureState = {
  displayType: DisplayTypeEnum.LOADING,
  redirect: '',
  auth: null,
  preConditionsCompleted: null,
}

export function ThreeDSecure({ checkout, cart }: ThreeDSecureProps): JSX.Element {

  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const [getStatus] = useGetPeachPaymentsPaymentStatusLazyQuery()
  const [authenticate3DSecure] = useAuthenticate3DSecureMutation()
  const [state, setState] = useState<ThreeDSecureState>({ ...DEFAULT_STATE })
  const iFrame: React.RefObject<HTMLIFrameElement> = useRef()
  const config = useConfig()

  const getTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  const submitPreConditionIframe = async (preCondition: PeachPayments3DSecurePreconditionFragment, index: number): Promise<void> => {
    const iframeUrl = `${getCurrentUrl()}/payments/peach/pre?${qs.stringify({ ...preCondition, index })}`
    setState((prevState) => update(prevState, {
      redirect: {
        $set: iframeUrl,
      },
      displayType: {
        $set: DisplayTypeEnum.PRECONDITION,
      },
    }))
  }

  const submitMainIframe = async (auth: ThreeDSecureFragment): Promise<void> => {
    const iframeUrl = `${getCurrentUrl()}/payments/peach/3d?${qs.stringify(auth)}`
    setState((prevState) => update(prevState, {
      redirect: {
        $set: iframeUrl,
      },
      displayType: {
        $set: DisplayTypeEnum.REDIRECT,
      },
    }))
  }

  const submitRequest = async (): Promise<void> => {
    const card: PeachPaymentsCardInput = {
      brand: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.brand as unknown as PeachPaymentsCreditCardTypeEnum,
      cvv: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.cvv,
      expiryMonth: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.expiryMonth,
      expiryYear: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.expiryYear,
      name: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.name,
      nickname: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.nickname,
      number: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.number.split(' ').join(''),
      saveCard: checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.saveCard,
    }

    const input: PeachPayments3DsInput = {
      card,
      cartId: cart.id,
      redirectTo: getRedirect(),
    }
    try {

      const response = await authenticate3DSecure({
        variables: {
          input,
        },
      })
      if (response.data.authenticate.canSkip_3ds) {
        CheckoutPlugin.shared().setCanSkipThreeDSecure(true)
      } else {
        setState((prevState) => update(prevState, {
          auth: {
            $set: response.data.authenticate,
          },
          preConditionsCompleted: {
            $set: 0,
          },
        }))
      }
    } catch (e) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.ERROR,
        },
      }))
    }
  }

  const checkResponse = async (paymentId: string): Promise<void> => {
    setState((prevState) => update(prevState, {
      displayType: {
        $set: DisplayTypeEnum.LOADING,
      },
    }))
    const status = await getStatus({ variables: { paymentId } })
    if (status.data.getPeachPaymentsPaymentStatus.simpleStatus === PeachPaymentsSimpleStatus.SUCCESS) {
      CheckoutPlugin.shared().setThreeDSecureId(paymentId)
    } else if (status.data.getPeachPaymentsPaymentStatus.simpleStatus === PeachPaymentsSimpleStatus.PENDING) {
      setTimeout(() => {
        checkResponse(paymentId)
      }, POLLING_INTERVAL)
    } else {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.ERROR,
        },
      }))
    }
  }

  const _handleReset = (): void => {
    CheckoutPlugin.shared().setPaymentMethod({ paymentMethod: null })
    setState((prevState) => update(prevState, {
      $set: DEFAULT_STATE,
    }))
  }

  useEffect(() => {
    if (!customerLoading) {
      submitRequest()
    }
  }, [customerLoading])

  useEffect(() => {
    if (state.preConditionsCompleted !== null) {
      if (state.preConditionsCompleted === state.auth.preconditions.length) {
        setTimeout(() => {
          submitMainIframe(state.auth)
        }, FORM_SUBMIT_TIMEOUT)
      } else {
        setTimeout(() => {
          submitPreConditionIframe(state.auth.preconditions[state.preConditionsCompleted], state.preConditionsCompleted)
        }, FORM_SUBMIT_TIMEOUT)
      }
    }
  }, [state.preConditionsCompleted])

  useEffect(() => {
    const _handler = async (event: MessageEvent): Promise<void> => {
      if (event.origin.startsWith(window.location.origin) && event.data.type === 'PEACH_RESPONSE' && event.data.id) {
        const { id } = event.data
        checkResponse(id)
      } else if (event.origin.startsWith(window.location.origin) && event.data.type === 'PEACH_PRE_RESPONSE' && event.data.index) {
        setState((prevState) => update(prevState, {
          preConditionsCompleted: {
            $set: prevState.preConditionsCompleted + 1,
          },
        }))
      }
    }
    if (config.isBrowser()) {
      window.addEventListener('message', _handler)
      return () => window.removeEventListener('message', _handler)
    }
  }, [])

  return (
    <Container>
      <Header>
        <Title variant='t3'>
          3D Secure
        </Title>
      </Header>
      <Content>
        <Choose>
          <When condition={state.displayType === DisplayTypeEnum.LOADING}>
            <Loader />
          </When>
          <When condition={state.displayType === DisplayTypeEnum.ERROR}>
            <ErrorContainer>
              <Heading variant='h1'>Sorry!</Heading>
              <Paragraph>An error was encountered with 3D Secure.</Paragraph>
              <Button title='TRY AGAIN' onClick={_handleReset} />
            </ErrorContainer>
          </When>
          <When condition={state.displayType === DisplayTypeEnum.REDIRECT || state.displayType === DisplayTypeEnum.PRECONDITION}>
            <IframeContainer>
              <iframe
                src={state.redirect}
                sandbox='allow-forms allow-scripts allow-same-origin'
                width='100%'
                height='100%'
                ref={iFrame} />
            </IframeContainer>
          </When>
        </Choose>
      </Content>
    </Container>
  )

}
