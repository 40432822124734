import { gql, ApolloQueryResult, DocumentNode, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import convert from 'color-convert'
import { parseNumber, CountryCode, ParsedNumber } from 'libphonenumber-js'

import { theme } from '@components/Theme'
import { MoneyFragment, ProductDetailsFragment_SimpleProduct_ as SimpleProduct } from '@hooks/api/index'

import { countryCodes } from './countrycodes'
import { currencyCodes } from './currentcycodes'

type ProductDetailsFragmentSimpleProductPriceRange = SimpleProduct['priceRange']

export interface Chunk<T> { start: number, end: number, total: number, items: T[] }

export interface CalculatePriceRange {
  regularPrice: string
  finalPrice: string
  discountPrice: number
  discountPercent: number
}

export class SiteHelper {

  static getUserRefetchQueries(): { query: DocumentNode }[] {
    return []
  }

  static getCountryCode(): string {
    if (typeof window !== 'undefined' && window.sessionStorage.getItem('lookup')) {
      return JSON.parse(window.sessionStorage.getItem('lookup')).countryCode
    }
    return 'ZA'
  }

  static getTelPrefixForCountryCode(countryCode: string): string {
    return countryCodes[countryCode].code
  }

  static getCountryCodeForTelPrefix(telPrefix: string): CountryCode {
    let countryCode = this.getCountryCode()
    Object.keys(countryCodes).forEach((country) => {
      const countryInfo = countryCodes[country]
      if (countryInfo.code === telPrefix) {
        countryCode = country
      }
    })
    return countryCode as CountryCode
  }

  static getCountryCodes(): { [k: string]: { name: string, code: string } } {
    return countryCodes
  }

  static setPrice(priceRange: ProductDetailsFragmentSimpleProductPriceRange): CalculatePriceRange {
    return {
      regularPrice: this.formatCurrency(priceRange.maximumPrice.regularPrice),
      finalPrice: this.formatCurrency(priceRange.maximumPrice.finalPrice),
      discountPrice: priceRange.maximumPrice.discount.amountOff,
      discountPercent: priceRange.maximumPrice.discount.percentOff,
    }
  }

  static formatCurrency(money: MoneyFragment): string {
    const currency = currencyCodes[money.currency]
    if (currency) {
      return `${currency.symbol}${money.value.toFixed(currency.decimalDigits)}`
    }
    return money.value.toFixed(2)
  }

  static validateEmail(value: string): boolean {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  static validateInt(value: string): boolean {
    return /\d*/.test(value)
  }

  static validatePhone(value: string): boolean {
    try {
      const values = value.split(' ')
      const prefix = this.getCountryCodeForTelPrefix(values.shift())
      const number = values.join(' ')

      if (prefix === 'ZA' && number.replace(/\s/g, '').length < 9) {
        return false
      }

      if (!number.trim()) {
        return false
      }
      if ((parseNumber(value, prefix) as ParsedNumber)?.phone !== undefined) {
        return true
      }
    } catch (e) {
      return false
    }
  }

  static scrollTo(to: number, duration: number, element: any = false): Promise<void> {
    return new Promise((resolve): void => {
      if (typeof window === 'undefined') {
        return resolve()
      } else {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        if (top === 0) {
          return resolve()
        }
      }
      let start: number
      if (!element) {
        start = window.scrollY
      } else {
        start = element.scrollTop
      }
      const change = to - start
      const increment = 20
      const animateScroll = (elapsedTime: number): void => {
        elapsedTime += increment
        const position = this.easeInOut(elapsedTime, start, change, duration)
        if (!element) {
          window.scrollTo(0, position)
        } else {
          element.scrollTop = position
        }
        if (elapsedTime < duration) {
          setTimeout(() => {
            animateScroll(elapsedTime)
          }, increment)
        } else {
          resolve()
        }
      }
      animateScroll(0)
    })
  }

  static easeInOut(currentTime: number, start: number, change: number, duration: number): number {
    currentTime /= duration / 2
    if (currentTime < 1) {
      return change / 2 * currentTime * currentTime + start
    }
    currentTime -= 1
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
  }

  static clone(data: any): any {
    return JSON.parse(JSON.stringify(data))
  }

  static parseFilters(filters: any): any {
    const response: any = {}
    filters.forEach((filter: any, key: string) => {
      response[key] = {}
      filter.enumValues.forEach((enumValue: any) => {
        response[key][enumValue.name] = enumValue.description
      })
    })
    return response
  }

  static randomString(size: number): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let randomString = ''
    for (let x = 0; x < size; x++) {
      const charIndex = Math.floor(Math.random() * characters.length)
      randomString += characters.substring(charIndex, charIndex + 1)
    }
    return randomString
  }

  static stripGraphQLTypes(input: any, stripIds = true): any {
    const newData = this.clone(input)
    const stripTypes = (data: any): void => {
      if (Array.isArray(data)) {
        data.forEach((item) => stripTypes(item))
      } else if (typeof data === 'object' && data) {
        Object.keys(data).forEach((key) => {
          if (key === '__typename') {
            delete data[key]
          } else if (key === 'id' && stripIds) {
            delete data[key]
          } else {
            stripTypes(data[key])
          }
        })
      }
    }
    stripTypes(newData)
    return newData
  }

  static async validateObjectForMutationInput(client: ApolloClient<NormalizedCacheObject>, object: { [k: string]: any }, inputName: string): Promise<{ [k: string]: any }> {
    const qry = gql`
      query GetDetailedInputParams($name: String!) {
        attributes: getDetailedInputObjectConfig(name: $name)
      }
    `
    const result: ApolloQueryResult<any> = await client.query({
      query: qry,
      variables: { name: inputName },
    })
    const attributes = result.data.attributes as { [k: string]: any }
    return this.parseDataFromObject(attributes, object)
  }

  static parseDataFromObject(object: { [k: string]: any }, data?: { [k: string]: any }): { [k: string]: any } {
    if (!data) {
      return
    }
    const responseObject: { [k: string]: any } = {}
    Object.keys(data).forEach((key) => {
      const value = data[key]
      if (object[key]) {
        switch (true) {
          case Array.isArray(object[key].type):
            if (typeof object[key].type[0] === 'object') {
              responseObject[key] = []
              for (let v = 0; v < value.length; v++) {
                responseObject[key].push(this.parseDataFromObject(object[key].type[0], value[v]))
              }
            } else {
              // TODO: recurse and validate for scalar array types
              responseObject[key] = []
              for (let v = 0; v < value.length; v++) {
                responseObject[key].push(value[v])
              }
            }
            break
          case typeof object[key].type === 'object':
            responseObject[key] = this.parseDataFromObject(object[key].type, value)
            break
          case object[key].type === 'Int':
            responseObject[key] = parseInt(value)
            break
          case object[key].type === 'Float':
          case object[key].type === 'Double':
            responseObject[key] = parseFloat(value)
            break
          default:
            responseObject[key] = value
            break
        }
      }
    })
    return responseObject
  }

  static generateToken(): string {
    return `${this.randomString(6)}-${this.randomString(6)}-${this.randomString(6)}`
  }

  static chunkArray(array: any[], size: number): any[][] {
    const result = []
    for (const value of array) {
      const lastArray = result[result.length - 1]
      if (!lastArray || lastArray.length === size) {
        result.push([value])
      } else {
        lastArray.push(value)
      }
    }
    return result
  }

  static chunkArrayAnnotated<T>(array: T[], size: number, total = array.length): Chunk<T>[] {
    const result: Chunk<T>[] = []
    for (let v = 0; v < array.length; v++) {
      const lastArray = result[result.length - 1]
      if (!lastArray || lastArray.items.length === size) {
        const end = size + v
        result.push({
          start: v,
          end: end > total ? total : end,
          total,
          items: [array[v]],
        })
      } else {
        lastArray.items.push(array[v])
      }
    }
    return result
  }

  static getOpaqueColor(color: string, opacity: number): string | null {
    if (!color) {
      return null
    }
    const rgb = convert.hex.rgb(color)
    const rgbString = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`
    return rgbString
  }

  static getSimpleToastConfig() {
    return {
      position: 'top-right',
      mobileWidth: '95%',
      tabletWidth: '95%',
      desktopWidth: 'auto',
      autoDismissTimeout: 2250,
      portalId: 'simple-toasts',
      simpleToastStyles: {
        success: {
          color: theme.colors.green.deYork,
        },
        error: {
          color: theme.colors.red.cinnabar,
        },
        warning: {
          color: theme.colors.orange.casablanca,
        },
        standard: {
          color: theme.colors.blue.curiousBlue,
        },
      },
    }
  }

  static isHTML = (str: string): boolean => {
    if (str.indexOf('<div') !== -1 || str.indexOf('<p') !== -1 || str.indexOf('<a') !== -1) {
      return true
    }
    return false
  }

  // static isHTML = (str: string): boolean => !(str || '')
  //   // replace html tag with content
  //   .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/ig, '')
  //   // remove remaining self closing tags
  //   .replace(/(<([^>]+)>)/ig, '')
  //   // remove extra space at start and end
  //   .trim()

  static getArrayDiff<T>(oldValues: T[], newValues: T[]): { add: T[], remove: T[], update: T[] } {
    const add: T[] = []
    const remove: T[] = []
    const update: T[] = []

    oldValues.forEach((val) => {
      if (newValues.includes(val)) {
        update.push(val)
      } else {
        remove.push(val)
      }
    })

    newValues.forEach((val) => {
      if (!oldValues.includes(val)) {
        add.push(val)
      }
    })

    return {
      add,
      remove,
      update,
    }
  }

}

export function memoize<A extends unknown[], R>(fun: (...args: A) => R): (...args: A) => R {
  const cache: { [k: string]: R } = {}
  return (...args: A) => {
    const stringifiedArgs = JSON.stringify(args)
    if (typeof cache[stringifiedArgs] === 'undefined') {
      cache[stringifiedArgs] = fun(...args)
    }
    return cache[stringifiedArgs]
  }
}

export function enumOptions(someEnum: { [k: string]: any }) {
  return {
    options: Object.keys(someEnum).map((_, index) => index),
    mapping: Object.values(someEnum),
    control: {
      type: 'select',
      labels: Object.keys(someEnum),
    },
  }
}
