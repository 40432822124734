import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Divider } from '@atoms/layout/Divider'
import { Loader } from '@atoms/notifications'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { LocalPeachPaymentsPaymentInfoFragment, PaymentMethodFragment, useCartQuery, useCustomerQuery, useGetCheckoutQuery } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { CheckBoxInput, Form, useForm } from '@molecules/inputs'
import { AddCreditCard } from '@organisms/user/AddCreditCard'
import { Addresses, AddressType, DisplayTypeEnum } from '@organisms/user/Addresses'
import { CreditCards } from '@organisms/user/CreditCards'
import { PaymentMethodEnum } from '@uctypes/api/globalTypes'

import { PaymentMethodRow } from './PaymentMethodRow'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const List = styled.div`
  
    padding: 0 0 5.000vw 0;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw 1.111vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px 16px 16px;
    }
  
`

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  
    width: CALC(100% -10.000vw);

    @media (min-width: 30em) {
      width: CALC(100% -10.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% -2.222vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% -32px);
    }
  
  
    margin: 5.000vw;

    @media (min-width: 30em) {
      margin: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 16px;
    }
  
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const AddressesContainer = styled.div`
  
    padding: 5.000vw;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 16px;
    }
  
`

const ScrollRefContainer = styled.div``

export interface PaymentMethodsProps {
  onContinue: () => void
}

enum CreditCardDisplayEnum {
  SELECT = 'SELECT',
  ADD = 'ADD',
  THREED = 'THREED'
}

interface PaymentMethodsState {
  creditCardDispay: CreditCardDisplayEnum
  useDifferentBillingAddress: boolean
}

const DEFAULT_STATE: PaymentMethodsState = {
  creditCardDispay: CreditCardDisplayEnum.SELECT,
  useDifferentBillingAddress: false,
}

export function PaymentMethods({ onContinue }: PaymentMethodsProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: cartData, loading: cartLoading } = useCartQuery({ variables: { cartId }, skip: !cartId })
  const { data: { checkout = CHECKOUT_DEFAULT_STATE } } = useGetCheckoutQuery()
  const { data: customerData } = useCustomerQuery({ variables: { pageSize: 100 } })
  const [state, setState] = useState<PaymentMethodsState>({ ...DEFAULT_STATE })
  const form = useForm()
  const scrollRef = useRef<HTMLDivElement>(null)

  const _handleSelect = (code: PaymentMethodEnum): void => {
    // onSetPaymentMethod(id)
    if (code === PaymentMethodEnum.ozow) {
      const cancelUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/checkout/place/failure`
      const successUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/checkout/place/success`
      CheckoutPlugin.shared().setPaymentMethod({
        paymentMethod: {
          code: PaymentMethodEnum.ozow,
          ozow: {
            cancelUrl,
            successUrl,
          },
        },
      })
    } else {
      CheckoutPlugin.shared().setPaymentMethod({
        paymentMethod: {
          code,
        },
      })
    }
  }

  const _handleCreditCardSelect = (creditCardId: string): void => {
    CheckoutPlugin.shared().setPaymentMethod({
      paymentMethod: {
        code: PaymentMethodEnum.peachpayments_s2s,
        peachpaymentsS2s: {
          token: creditCardId,
        },
      },
    })
  }

  const _handleAddCreditCard = (): void => {
    setState((prevState) => update(prevState, {
      creditCardDispay: {
        $set: CreditCardDisplayEnum.ADD,
      },
    }))
  }

  const _handleCancelAddCreditCard = (): void => {
    setState((prevState) => update(prevState, {
      creditCardDispay: {
        $set: CreditCardDisplayEnum.SELECT,
      },
    }))
  }

  const _handleAddressOptionChange = (): void => {
    if (!state.useDifferentBillingAddress) {
      CheckoutPlugin.shared().setBillingAddress({ billingAddressId: null })
    }
    setState((prevState) => update(prevState, {
      useDifferentBillingAddress: {
        $set: !prevState.useDifferentBillingAddress,
      },
    }))
  }

  const _handleAddressSelected = (billingAddressId: number): void => {
    CheckoutPlugin.shared().setBillingAddress({ billingAddressId })
  }

  const _handleAddCreditCardSuccess = (creditCardInfo: LocalPeachPaymentsPaymentInfoFragment): void => {
    CheckoutPlugin.shared().setPaymentMethod({
      paymentMethod: {
        code: PaymentMethodEnum.peachpayments_s2s,
        peachpaymentsS2s: { ...creditCardInfo },
      },
    })
  }

  const _handleScroll = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (customerData?.paymentTokens?.items?.length) {
      setState((prevState) => update(prevState, {
        creditCardDispay: {
          $set: CreditCardDisplayEnum.SELECT,
        },
      }))
    } else {
      setState((prevState) => update(prevState, {
        creditCardDispay: {
          $set: CreditCardDisplayEnum.ADD,
        },
      }))
    }
  }, [])

  useEffect(() => {
    if (state.creditCardDispay === CreditCardDisplayEnum.ADD || state.creditCardDispay === CreditCardDisplayEnum.SELECT) {
      _handleScroll()
    }
  }, [_handleSelect])

  const paymentMethods: Readonly<PaymentMethodFragment[]> = cartData?.cart?.availablePaymentMethods
  const loading = cartLoading

  let paymentMethod!: PaymentMethodFragment
  let paymentMethodIndex!: number

  return (
    <>
      <Container>
        <Header>
          <Title variant='t3'>
          Payment
          </Title>
        </Header>
        <Choose>
          <When condition={loading}>
            <Loader />
          </When>
          <When condition={paymentMethods.length === 0}>
            <Empty>
              <Paragraph>
              No payment methods available.
              </Paragraph>
            </Empty>
          </When>
          <Otherwise>
            <List>
              <For each='paymentMethod' index='paymentMethodIndex' of={paymentMethods}>
                <PaymentMethodRow
                  paymentMethod={paymentMethod}
                  selected={paymentMethod.code === checkout?.paymentInfo?.paymentMethod?.code}
                  key={paymentMethod.code}
                  onSelect={() => _handleSelect(paymentMethod.code as PaymentMethodEnum)} />
                <If condition={paymentMethodIndex < paymentMethods.length - 1}>
                  <Divider />
                </If>
              </For>
            </List>
          </Otherwise>
        </Choose>
      </Container>
      <If condition={checkout?.paymentInfo?.paymentMethod?.code === PaymentMethodEnum.peachpayments_s2s}>
        <Choose>
          <When condition={state.creditCardDispay === CreditCardDisplayEnum.SELECT}>
            <ScrollRefContainer ref={scrollRef}>
              <CreditCards
                onSelect={_handleCreditCardSelect}
                selected={checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.token}
                onAdd={_handleAddCreditCard} />
            </ScrollRefContainer>
          </When>
          <When condition={state.creditCardDispay === CreditCardDisplayEnum.ADD}>
            <ScrollRefContainer ref={scrollRef}>
              <AddCreditCard
                onCancel={_handleCancelAddCreditCard}
                onSuccess={_handleAddCreditCardSuccess}
                onContinue={onContinue} />
            </ScrollRefContainer>
          </When>
        </Choose>
      </If>
      <AddressesContainer>
        <Form form={form} onValuesChange={_handleAddressOptionChange} initialValues={{ useShippingAddress: [true] }}>
          <CheckBoxInput
            wrapperClassName='no-grow-checkbox'
            name='useShippingAddress'
            variant='horizontal'
            options={[{ title: 'Use shipping address for billing', value: true }]} />
        </Form>
      </AddressesContainer>
      <If condition={state.useDifferentBillingAddress}>
        <Addresses
          selected={checkout.paymentInfo?.billingAddressId}
          onSelect={_handleAddressSelected}
          forceAdd
          addressType={AddressType.BILLING}
          displayType={DisplayTypeEnum.LIST} />
      </If>
    </>
  )

}
