import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE, AppPlugin } from '@api/local/AppPlugin'
import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { DropDownContainer } from '@atoms/index'
import { Card, LayoutCol, LayoutGrid, LayoutRow } from '@atoms/layout'
import { UltraContainer } from '@atoms/misc'
import { Label, Loader } from '@atoms/notifications'
import { Heading, Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useAddCouponCodeToCartMutation, useCartQuery, useRemoveCouponFromCartMutation, CartItemFragment, useCustomerQuery, useGetAppQuery, useCustomerRewardsQuery, useUseRewardPointsMutation, useRewardsQuery, useGetRelatedProductsQuery, ProductListFragment } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { DesktopProductCartCard, MobileCartCheckout, MobileProductCartCard } from '@molecules/store'
import { CartSummary } from '@molecules/store/CartSummary'
import { ProductSlider } from '@organisms/index'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, DeviceTypeEnum, EntityTypeEnum, MobileOSTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'
import { SearchEngineOptimizationParams, SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const Container = styled(UltraContainer)`


  .drop-down-item {
    
    margin-top: 3.750vw;

    @media (min-width: 30em) {
      margin-top: 3.750vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.833vw;
    }

    @media (min-width: 90em) {
      margin-top: 12px;
    }
  
  }

  .points-disclaimer {
    
    margin-top: 5.000vw;

    @media (min-width: 30em) {
      margin-top: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.111vw;
    }

    @media (min-width: 90em) {
      margin-top: 16px;
    }
  
  }

  .mobile-summary {
    
    margin-top: 1.250vw;

    @media (min-width: 30em) {
      margin-top: 1.250vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.278vw;
    }

    @media (min-width: 90em) {
      margin-top: 4px;
    }
  
  }

  
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  
    height: 100vh;

    @media (min-width: 30em) {
      height: 100vh;
    }

    @media (min-width: 30.0625em) {
      height: 100%;
    }

    @media (min-width: 90em) {
      height: 100%;
    }
  
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  
    gap: 6.250vw;

    @media (min-width: 30em) {
      gap: 10.625vw;
    }

    @media (min-width: 30.0625em) {
      gap: 2.361vw;
    }

    @media (min-width: 90em) {
      gap: 34px;
    }
  
  
    margin: 2.500vw 5.000vw;

    @media (min-width: 30em) {
      margin: 7.500vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.667vw 0 0;
    }

    @media (min-width: 90em) {
      margin: 24px 0 0;
    }
  

  .breadcrumbs {
    
    display: none;

    @media (min-width: 30em) {
      display: flex;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }

  .cart-heading {
    
    margin: 0;

    @media (min-width: 30em) {
      margin: 0;
    }

    @media (min-width: 30.0625em) {
      margin: 0.833vw 0;
    }

    @media (min-width: 90em) {
      margin: 12px 0;
    }
  
    
    width: 37.500vw;

    @media (min-width: 30em) {
      width: 37.500vw;
    }

    @media (min-width: 30.0625em) {
      width: auto;
    }

    @media (min-width: 90em) {
      width: auto;
    }
  
  }

`

const Strong = styled.span`
  font-weight: 700;
  color: ${(props): string => props.theme.colors.green.bottleGreen};
`

const ProductsList = styled.div`

`

const PromoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
  
    gap: 3.125vw;

    @media (min-width: 30em) {
      gap: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.694vw;
    }

    @media (min-width: 90em) {
      gap: 10px;
    }
  

  .promo-item {
    flex-grow: 1;
    margin: 0;
  }

  .input {
    
    height: 9.375vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  
    
    margin-top: 1.563vw;

    @media (min-width: 30em) {
      margin-top: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.347vw;
    }

    @media (min-width: 90em) {
      margin-top: 5px;
    }
  
    background-color: ${(props): string => props.theme.colors.grey.pampas};
  }
  
`

const PromoLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  
    gap: 1.563vw;

    @media (min-width: 30em) {
      gap: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.347vw;
    }

    @media (min-width: 90em) {
      gap: 5px;
    }
  
  
    margin-top: 4.688vw;

    @media (min-width: 30em) {
      margin-top: 4.688vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 1.042vw;
    }

    @media (min-width: 90em) {
      margin-top: 15px;
    }
  
`

const Fixed = styled.div<{ isApp: boolean, isIos: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${props => (props.isIos && props.isApp) ? ResponsivePXValue('padding-bottom', '20px') : ''}
  z-index: 10;
`

const DeliveryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
    gap: 1.875vw;

    @media (min-width: 30em) {
      gap: 1.875vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.417vw;
    }

    @media (min-width: 90em) {
      gap: 6px;
    }
  
  
    height: 13.750vw;

    @media (min-width: 30em) {
      height: 13.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.056vw;
    }

    @media (min-width: 90em) {
      height: 44px;
    }
  
  
    margin-top: 12.500vw;

    @media (min-width: 30em) {
      margin-top: 12.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 2.778vw;
    }

    @media (min-width: 90em) {
      margin-top: 40px;
    }
  
`

const DeliveryIconContainer = styled.div`
  
    width: 9.375vw;

    @media (min-width: 30em) {
      width: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      width: 2.083vw;
    }

    @media (min-width: 90em) {
      width: 30px;
    }
  
  
    height: 9.375vw;

    @media (min-width: 30em) {
      height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      height: 2.083vw;
    }

    @media (min-width: 90em) {
      height: 30px;
    }
  
`

const Empty = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
    margin-bottom: initial;

    @media (min-width: 30em) {
      margin-bottom: initial;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 1.250vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 18px;
    }
  
  .empty-text {
    margin: 0;
  }
`

const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    padding: 5.000vw 0;

    @media (min-width: 30em) {
      padding: 17.500vw 0;
    }

    @media (min-width: 30.0625em) {
      padding: 2.778vw 0;
    }

    @media (min-width: 90em) {
      padding: 40px 0;
    }
  
`

const BottomSpacer = styled.div`
  width: 100%;
  
    height: 6.250vw;

    @media (min-width: 30em) {
      height: 37.500vw;
    }

    @media (min-width: 30.0625em) {
      height: 3.333vw;
    }

    @media (min-width: 90em) {
      height: 48px;
    }
  
`
const RelatedContent = styled.div`
  
    padding-top: 6.250vw;

    @media (min-width: 30em) {
      padding-top: initial;
    }

    @media (min-width: 30.0625em) {
      padding-top: initial;
    }

    @media (min-width: 90em) {
      padding-top: initial;
    }
  
  background-color: ${(props): string => props.theme.colors.white.pampas};
`
const RelatedProductsWrapper = styled.div<{ spaceBelow: boolean }>`
  width: 100%;
  overflow-x: auto;
  .recommended {
    ${(props) => props.spaceBelow ? ResponsivePXValue('padding', { mobile: '0 0 96px', tablet: '32px 0', desktop: '16px 104px' }) : ResponsivePXValue('padding', { mobile: '0', tablet: '32px 0 32px', desktop: '16px 104px 0' })}
  }

  .filter-title {
    margin: 0;
  }

  .recommended .filter-title {
    
    font-size: 5.000vw;

    @media (min-width: 30em) {
      font-size: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      font-size: 1.667vw;
    }

    @media (min-width: 90em) {
      font-size: 24px;
    }
  
    
    line-height: 6.875vw;

    @media (min-width: 30em) {
      line-height: 9.375vw;
    }

    @media (min-width: 30.0625em) {
      line-height: 2.083vw;
    }

    @media (min-width: 90em) {
      line-height: 30px;
    }
  
  }
  .slider {
    
    margin-bottom: 3.125vw;

    @media (min-width: 30em) {
      margin-bottom: 3.125vw;
    }

    @media (min-width: 30.0625em) {
      margin-bottom: 0.694vw;
    }

    @media (min-width: 90em) {
      margin-bottom: 10px;
    }
  
  }
`

const RelatedProductsHeader = styled.div<{ sliderKey: number }>`
 
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.green.swansDown};
  
    padding: 7.813vw 3.750vw 7.813vw;

    @media (min-width: 30em) {
      padding: 7.813vw 3.750vw 7.813vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.736vw 0.833vw 1.736vw;
    }

    @media (min-width: 90em) {
      padding: 25px 12px 25px;
    }
  

`

const HeadingWrapper = styled.div`
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  margin: 0 auto;
`

const MobileNavSpacer = styled.div`
  display: block;
  
    height: 37.500vw;

    @media (min-width: 30em) {
      height: 0;
    }

    @media (min-width: 30.0625em) {
      height: 0;
    }

    @media (min-width: 90em) {
      height: 0;
    }
  ;
  width: 100%;
`

export interface CartProps {
  isModal?: boolean
  onClose?: () => void
}

export function Cart({ isModal = false, onClose }: CartProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: rewards, loading: rewardsLoading } = useRewardsQuery()
  const { data: cartData, loading: cartLoading } = useCartQuery({ variables: { cartId }, skip: !cartId })
  const [addCouponCodeToCart, { loading: addCouponLoading }] = useAddCouponCodeToCartMutation()
  const [useRewardPoints] = useUseRewardPointsMutation()
  const [removeCouponFromCart, { loading: removeCouponLoading }] = useRemoveCouponFromCartMutation()
  const theme = useTheme()
  const navigate = useNavigate()
  const couponForm = useForm()
  const pointsForm = useForm()
  const { addToast } = useSimpleToasts()
  const events = useEvents()

  useEffect(() => {
    if (pointsForm) {
      pointsForm.setFieldsValue({ amount: '0' })
    }
  }, [])

  const { data: relatedProductsData } = useGetRelatedProductsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      slug: cartData?.cart?.items?.[0]?.product?.urlKey,
    },
    skip: !cartData?.cart?.items,
  })

  const _handleApplyPromo = async ({ code }: { code: string }): Promise<void> => {
    try {
      if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
        ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
      } else {
        await addCouponCodeToCart({
          variables: {
            input: {
              cartId: cartData.cart.id,
              couponCode: code,
            },
          },
        })
        couponForm.resetFields()
      }

    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleRemovePromo = async (): Promise<void> => {
    try {
      await removeCouponFromCart({
        variables: {
          input: {
            cartId: cartData.cart.id,
          },
        },
      })
      couponForm.resetFields()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _useRewardPoints = async ({ amount }: {amount: string}): Promise<void> => {
    try {
      const results = await useRewardPoints({
        variables: {
          input: {
            cartId: cartData.cart.id,
            points: parseFloat(amount),
          },
        },
      })
      addToast({
        message: results.data?.useRewardPoints?.response,
        appearance: 'success',
      })
      pointsForm.setFieldsValue({ amount: '0' })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleLogin = (): void => {
    if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
  }

  useEffect(() => {
    if (!isModal) {
      AppPlugin.shared().hideFooter()
      return () => {
        AppPlugin.shared().showFooter()
      }
    } else {
      events.hasViewedPage({
        url: '/cart',
        title: 'Cart',
        type: EntityTypeEnum.PRODUCT,
      })
    }
  }, [])

  useEffect(() => {
    if (cartData?.cart) {
      events.hasViewedCart(cartData.cart)
    }
  }, [cartData?.cart])

  let cartItem: CartItemFragment
  let cartItemIndex: number
  let promo: { code: string }
  const loading = cartLoading || customerLoading

  const seo: SearchEngineOptimizationParams = {
    name: 'Cart',
    title: `Cart${cartData?.cart ? ` (${cartData.cart.totalQuantity})` : ''}`,
    meta: [], // <---- TODO
  }

  const hasRewardPoints = rewards?.rewards?.balance > 0
  
  return (
    <>
      <Container>
        <SearchEngineOptimization seo={seo} />
        <LayoutGrid>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: cartData?.cart?.items?.length ? 8 : 12 }}>
              <If condition={!isModal}>
                <HeaderContainer>
                  <Heading variant='h3' className='cart-heading'>
                    Shopping Cart
                  </Heading>
                  <Paragraph color={theme.colors.grey.silver}>
                    ({cartData?.cart?.totalQuantity || 0}) Items
                  </Paragraph>
                </HeaderContainer>
              </If>
              <Choose>
                <When condition={loading}>
                  <LoadingContainer>
                    <Loader />
                  </LoadingContainer>
                </When>
                <When condition={cartData?.cart?.items?.length === 0}>
                  <Card>
                    <DeviceContainer mobile>
                      <EmptyContent>
                        <Empty>
                          <Paragraph variant='p3' className='empty-text'>Your cart is empty</Paragraph>
                        </Empty>
                        <Button variant='primary' size='small' title='CONTINUE SHOPPING' onClick={onClose} />
                      </EmptyContent>
                    </DeviceContainer>
                    <DeviceContainer tablet desktop>
                      <EmptyContent>
                        <Empty>
                          <Paragraph variant='p1' className='empty-text'>Your cart is empty</Paragraph>
                        </Empty>
                        <Button variant='primary' size='medium' title='CONTINUE SHOPPING' onClick={() => navigate('/')} />
                      </EmptyContent>
                    </DeviceContainer>
                  </Card>
                </When>
                <Otherwise>
                  <DeviceContainer desktop tablet>
                    <ProductsList>
                      <For each='cartItem' of={cartData?.cart.items || []} index='cartItemIndex'>
                        <DesktopProductCartCard cartItem={cartItem} cart={cartData?.cart} key={cartItemIndex} />
                      </For>
                    </ProductsList>
                  </DeviceContainer>
                  <DeviceContainer mobile>
                    <ProductsList>
                      <For each='cartItem' of={cartData?.cart.items || []} index='cartItemIndex'>
                        <MobileProductCartCard cartItem={cartItem} cart={cartData?.cart} key={cartItemIndex} onClose={onClose}/>
                      </For>
                    </ProductsList>
                    <CartSummary
                      className='mobile-summary'
                      includeCheckout={false}
                      cart={cartData?.cart}
                      customer={customerData?.currentCustomer} />

                    <If condition={relatedProductsData?.product?.relatedProducts.length > 0}>
                      <RelatedContent>
                        <RelatedProductsWrapper spaceBelow={(relatedProductsData?.product?.upsellProducts.length === 0 || relatedProductsData?.product?.crosssellProducts.length === 0)}>
                          <RelatedProductsHeader sliderKey={0}>
                            <Heading variant='h3' className='filter-title' align='left' color={theme.colors.green.greenVogue}>
                            Recommended products
                            </Heading>
                          </RelatedProductsHeader>
                          <UltraContainer className='recommended'>
                            <ProductSlider
                              showCount={false}
                              products={relatedProductsData?.product?.relatedProducts as unknown as ProductListFragment[]}
                              total={relatedProductsData?.product?.relatedProducts.length}/>
                          </UltraContainer>
                        </RelatedProductsWrapper>
                      </RelatedContent>
                    </If>
                  </DeviceContainer>
                </Otherwise>
              </Choose>
            </LayoutCol>
            <LayoutCol span={{ mobile: 0, tablet: 0, desktop: cartData?.cart?.items?.length ? 4 : 0 }}>
              <DeliveryContainer>
                <If condition={cartData?.cart?.prices?.subTotal?.value > 400}>
                  <DeliveryIconContainer>
                    <Icon icon={LocalIconEnums.CARBON_NEUTRAL} color={theme.colors.green.bottleGreen} />
                  </DeliveryIconContainer>
                  <Paragraph variant='p2'>
                    You&apos;ve earned free carbon neutral delivery or collection
                  </Paragraph>
                </If>
              </DeliveryContainer>
              <Choose>
                <When condition={!!cartData?.cart}>
                  <CartSummary cart={cartData.cart} customer={customerData?.currentCustomer} />
                </When>
              </Choose>
              <DropDownContainer
                className='drop-down-item'
                title='Promo Codes & Gift Vouchers'
                titleColor={theme.colors.green.bottleGreen}
                headerColor={theme.colors.green.magicMint}
                onClick={_handleLogin}>
                <Form
                  loading={addCouponLoading || removeCouponLoading}
                  form={couponForm}
                  onFinish={_handleApplyPromo}>
                  <PromoContainer>
                    <TextInput
                      name='code'
                      label='Promo Code'
                      className='input'
                      wrapperClassName='promo-item'
                      placeholder='Enter Promo Code' />
                    <Button
                      loading={addCouponLoading || removeCouponLoading}
                      title='APPLY'
                      variant='ghost'
                      size='medium'
                      className='promo-button'
                      onClick={couponForm.submit} />
                  </PromoContainer>
                </Form>
                <If condition={!!cartData?.cart?.appliedCoupons?.length}>
                  <PromoLabels>
                    <For each='promo' of={cartData?.cart?.appliedCoupons || []}>
                      <Label
                        key={promo.code}
                        onClose={_handleRemovePromo}
                        color={theme.colors.green.bottleGreen}
                        backgroundColor={theme.colors.green.magicMint}>
                        {promo.code}
                      </Label>
                    </For>
                  </PromoLabels>
                </If>
              </DropDownContainer>
              <DropDownContainer
                className='drop-down-item'
                contentClassName='points-drop-down'
                title='Spend Faithful points'
                titleColor={theme.colors.green.bottleGreen}
                headerColor={theme.colors.green.snowFlurry}
                onClick={_handleLogin}>

                <Tag variant='t2' className='points-text' align='center'>
                  <Choose>
                    <When condition={rewards?.rewards?.balance === undefined}>
                      Please log in to view your Faithful points
                    </When>
                    <Otherwise>
                      You have <Strong>{(rewards?.rewards?.balance) ? rewards?.rewards?.balance : '0'}</Strong> Faithful points
                    </Otherwise>
                  </Choose>
                </Tag>

                <If condition={rewards?.rewards?.balance > 0}>
                  <Form form={pointsForm} onFinish={_useRewardPoints}>
                    <PromoContainer>
                      <TextInput
                        name='amount'
                        label='Faithful Points'
                        className='input'
                        wrapperClassName='promo-item'
                        placeholder='Enter Amount'
                        rules={[{ required: true, message: 'Please enter an amount' }]} />
                      <Button
                        title='APPLY'
                        variant='ghost'
                        size='medium'
                        className='promo-button'
                        onClick={() => pointsForm.submit()} />
                    </PromoContainer>
                  </Form>
                  <Tag variant='t3' className='points-disclaimer' align='center' color={theme.colors.green.greenVogue}>
                    Faithful Points are only deducted when order is confirmed.
                  </Tag>
                </If>
              </DropDownContainer>
            </LayoutCol>
          </LayoutRow>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <BottomSpacer />
            </LayoutCol>
          </LayoutRow>
        </LayoutGrid>
        <If condition={!!cartData?.cart?.items?.length}>
          <DeviceContainer mobile>
            <Fixed isApp={appData.app.isNativeApp} isIos={appData.app.mobileOSType === MobileOSTypeEnum.APPLE}>
              <Choose>
                <When condition={!!cartData}>
                  <MobileCartCheckout cart={cartData.cart} customer={customerData?.currentCustomer} />
                </When>
              </Choose>
            </Fixed>
          </DeviceContainer>
        </If>
      </Container>
      
      <If condition={!(appData.app.deviceType === DeviceTypeEnum.MOBILE)}>
        <If condition={relatedProductsData?.product?.relatedProducts.length > 0}>
          <LayoutGrid >
            <LayoutRow>
              <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
                <RelatedProductsWrapper spaceBelow={(relatedProductsData?.product?.upsellProducts.length === 0 || relatedProductsData?.product?.crosssellProducts.length === 0)} className='slider'>
                  <RelatedProductsHeader sliderKey={0}>
                    <HeadingWrapper>
                      <Heading variant='h3' className='filter-title' align='left' color={theme.colors.green.greenVogue}>
                      Recommended products
                      </Heading>
                    </HeadingWrapper>
                  </RelatedProductsHeader>
                  <ProductSlider
                    showCount={false}
                    products={relatedProductsData?.product?.relatedProducts as unknown as ProductListFragment[]}
                    total={relatedProductsData?.product?.relatedProducts.length}/>
                </RelatedProductsWrapper>
              </LayoutCol>
            </LayoutRow>
          </LayoutGrid> 
        </If>
      </If>
      <MobileNavSpacer />
    </>
  )
}
