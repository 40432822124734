import { ApolloClient, DocumentNode, FetchResult, FieldPolicy, NormalizedCacheObject, gql, makeVar } from '@apollo/client'

import update from 'react-addons-update'
import { v4 } from 'uuid'

import { DeliveryInfoFragment, PaymentInfoFragment, CheckoutFragment, CartFragment, RemoveItemFromCartMutationDocument, ChangeCartItemQuantityMutationDocument, SelectedBundleOptionfragment, AddProductToCartMutationDocument, AddConfigurableProductToCartMutationDocument, AddBundleProductToCartMutationDocument, CartItemFragment, BundleCartItemFragment, ConfigurableCartItemFragment, CartQueryDocument, PargoLocationFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'
import { CheckoutStepEnum, PlaceStepEnum, DeliveryStepEnum, PaymentMethodEnum, PaymentStepEnum, ShippingActionEnum, BundleOptionInput, PaymentMethodInput, LocalPeachPaymentsPaymentInfo, LocalOzowPaymentInfo } from '@uctypes/api/globalTypes'

interface SetDeliveryAddressArgs {
  deliveryAddressId: number
}

interface SetBillingAddressArgs {
  billingAddressId: number | null
}

interface SetDeliveryMethodArgs {
  deliveryMethod: string
  carrierCode: string
  rateUid: string
}

interface SetDeliveryActionArgs {
  deliveryAction: ShippingActionEnum
}

interface SetRequiresDeliveryActionArgs {
  requiresDeliveryAction: boolean
}

interface SetPaymentMethodArgs {
  paymentMethod: PaymentMethodEnum
}

interface SetHasCreditCardsArgs {
  hasCreditCards: boolean
}

export const DELIVERY_DEFAULT_STATE: DeliveryInfoFragment = {
  id: v4(),
  step: DeliveryStepEnum.DELIVERY_ADDRESS,
  rateUid: null,
  hasSetInfo: false,
  deliveryMethod: null,
  deliveryAction: null,
  carrierCode: null,
  requiresDeliveryAction: false,
  requiresPargoLocation: false,
  pargoLocation: null,
  deliveryAddressId: null,
  __typename: 'DeliveryInfo',
}

export const PAYMENT_DEFAULT_STATE: PaymentInfoFragment = {
  id: v4(),
  step: PaymentStepEnum.PAYMENT_METHOD,
  hasSetInfo: false,
  paymentMethod: null,
  canSkipThreeDSecure: false,
  billingAddressId: null,
  // localPaymentMethodInfo: null,
  __typename: 'PaymentInfo',
}

export const CHECKOUT_DEFAULT_STATE: CheckoutFragment = {
  id: v4(),
  step: CheckoutStepEnum.DELIVERY,
  canAdvance: false,
  showSteps: true,
  deliveryInfo: {
    ...DELIVERY_DEFAULT_STATE,
  },
  paymentInfo: {
    ...PAYMENT_DEFAULT_STATE,
  },
  // TODO: change these after testing
  confirmInfo: {
    id: v4(),
    step: PlaceStepEnum.PLACE_ORDER,
    __typename: 'ConfirmInfo',
  },
  __typename: 'Checkout',
}

const PAYMENT_METHOD_DEFAULT_STATE: any = {
  id: v4(),
  code: '',
  ozow: null,
  peachpaymentsS2s: null,
  __typename: 'LocalPaymentMethodInfoInput',
}

const DEFAULT_PEACH_PAYMENTS: LocalPeachPaymentsPaymentInfo = {
  id: v4(),
  brand: null,
  cvv: null,
  expiryMonth: null,
  expiryYear: null,
  name: null,
  nickname: null,
  number: null,
  saveCard: null,
  threeDSecure: null,
  token: null,
  __typename: 'LocalPeachPaymentsPaymentInfo',
}

const DEFAULT_OZOW: LocalOzowPaymentInfo = {
  id: v4(),
  cancelUrl: null,
  successUrl: null,
  __typename: 'LocalOzowPaymentInfo',
}
// _data
const _data = makeVar<CheckoutFragment>({ ...CHECKOUT_DEFAULT_STATE })

export class CheckoutPlugin implements ConfigPlugin {

  static instance: CheckoutPlugin

  static shared(): CheckoutPlugin {
    if (!this.instance) {
      this.instance = new CheckoutPlugin()
    }
    return this.instance
  }

  reset(): void {
    _data(update(_data(), {
      $set: { ...CHECKOUT_DEFAULT_STATE },
    }))
    this.validate()
  }

  shouldRemoveProblemItemsFromCart(cart: CartFragment): boolean {
    if (_data().deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE && cart?.shippingAddresses?.[0]?.shippingActions?.shortageItems.length) {
      return true
    }
    return false
  }

  shouldAddProblemItemsBackToCart(): boolean {
    const removedItemsString = sessionStorage.getItem('REMOVED_CART_ITEMS')
    if (removedItemsString) {
      return true
    }
    return false
  }

  async removeProblemItemsFromCart(cart: CartFragment, client: ApolloClient<NormalizedCacheObject>) {
    if (_data().deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE) {
      const shippingAddress = cart?.shippingAddresses?.[0]
      const removeItems: { previousQuantity: number, newQuantity: number, item: CartItemFragment }[] = []
      const promises: Promise<FetchResult<any>>[] = []
      for (let a = 0; a < shippingAddress?.shippingActions?.shortageItems.length; a++) {
        const cartItem = cart.items.find((itm) => itm.uid === (shippingAddress?.shippingActions?.shortageItems[a].cartParentItemUid || shippingAddress?.shippingActions?.shortageItems[a].cartItemUid))
        const quantity = shippingAddress?.shippingActions?.shortageItems[a].qty
        removeItems.push({ previousQuantity: cartItem.quantity, newQuantity: cartItem.quantity - quantity, item: { ...cartItem } })
        if (quantity === cartItem.quantity) {
          promises.push(client.mutate({
            mutation: RemoveItemFromCartMutationDocument,
            variables: {
              cartId: cart.id,
              cartItemUid: cartItem.uid,
            },
          }))
        } else {
          promises.push(client.mutate({
            mutation: ChangeCartItemQuantityMutationDocument,
            variables: {
              cartId: cart.id,
              cartItemUid: cartItem.uid,
              quantity: cartItem.quantity - quantity,
            },
          }))
        }
      }
      await Promise.all(promises)
      await client.refetchQueries({ include: [CartQueryDocument] })
      sessionStorage.setItem('REMOVED_CART_ITEMS', JSON.stringify(removeItems))
    }
  }

  async addProblemItemsBackToCart(cart: CartFragment, client: ApolloClient<NormalizedCacheObject>) {
    const removedItemsString = sessionStorage.getItem('REMOVED_CART_ITEMS')
    if (removedItemsString) {
      const removedItems: { previousQuantity: number, newQuantity: number, item: CartItemFragment }[] = JSON.parse(removedItemsString)
      const promises: Promise<FetchResult<any>>[] = []
      for (let r = 0; r < removedItems.length; r++) {
        const existingCartItem = cart.items.find((itm) => itm.product.sku === removedItems[r].item.product.sku)
        if (existingCartItem) {
          promises.push(client.mutate({
            mutation: ChangeCartItemQuantityMutationDocument,
            variables: {
              cartId: cart.id,
              cartItemUid: existingCartItem.uid,
              quantity: removedItems[r].previousQuantity,
            },
          }))
        } else if (removedItems[r].item.__typename === 'BundleCartItem') {
          const previousBundleOptions: SelectedBundleOptionfragment[] = (removedItems[r].item as BundleCartItemFragment).bundleOptions
          const bundleOptions: BundleOptionInput[] = []
          previousBundleOptions.forEach((pbo) => {
            pbo.values.forEach((va) => {
              bundleOptions.push({
                id: pbo.id,
                value: [va.id + ''],
                quantity: va.quantity,
              })
            })
          })
          promises.push(client.mutate({
            mutation: AddBundleProductToCartMutationDocument,
            variables: {
              input: {
                cartId: cart.id,
                cartItems: [{
                  bundleOptions,
                  data: {
                    quantity: removedItems[r].previousQuantity,
                    sku: removedItems[r].item.product.sku,
                  },
                }],
              },
            },
          }))
        } else if (removedItems[r].item.__typename === 'ConfigurableCartItem') {
          promises.push(client.mutate({
            mutation: AddConfigurableProductToCartMutationDocument,
            variables: {
              input: {
                cartId: cart.id,
                cartItems: [{
                  parentSku: removedItems[r].item.product.sku,
                  variantSku: removedItems[r].item.product.sku,
                  data: {
                    parentSku: removedItems[r].item.product.sku,
                    sku: (removedItems[r].item as ConfigurableCartItemFragment).configuredVariant.sku,
                    quantity: removedItems[r].previousQuantity,
                  },
                }],
              },
            },
          }))
        } else {
          promises.push(client.mutate({
            mutation: AddProductToCartMutationDocument,
            variables: {
              cartId: cart.id,
              sku: removedItems[r].item.product.sku,
              quantity: removedItems[r].previousQuantity,
            },
          }))
        }
      }
      await Promise.all(promises)
      await client.refetchQueries({ include: [CartQueryDocument] })
    }
    sessionStorage.removeItem('REMOVED_CART_ITEMS')
  }

  setStep(step: CheckoutStepEnum): void {
    _data(update(_data(), {
      step: {
        $set: step,
      },
    }))
    this.validate()
  }

  setDeliveryStep(step: DeliveryStepEnum): void {
    _data(update(_data(), {
      deliveryInfo: {
        step: {
          $set: step,
        },
      },
    }))
    this.validate()
  }

  setPaymentStep(step: PaymentStepEnum): void {
    _data(update(_data(), {
      paymentInfo: {
        step: {
          $set: step,
        },
      },
    }))
    this.validate()
  }

  setConfirmStep(step: PlaceStepEnum): void {
    _data(update(_data(), {
      confirmInfo: {
        step: {
          $set: step,
        },
      },
    }))
    this.validate()
  }

  toggleSteps(visible: boolean): void {
    _data(update(_data(), {
      showSteps: {
        $set: visible,
      },
    }))
    this.validate()
  }

  setDeliveryAddress({ deliveryAddressId }: SetDeliveryAddressArgs): void {
    _data(update(_data(), {
      deliveryInfo: {
        $set: {
          ...DELIVERY_DEFAULT_STATE,
          deliveryAddressId,
        },
      },
    }))
    this.validate()
  }

  setBillingAddress({ billingAddressId }: SetBillingAddressArgs): void {
    _data(update(_data(), {
      paymentInfo: {
        billingAddressId: {
          $set: billingAddressId,
        },
        pargoLocation: {
          $set: null,
        },
        requiresPargoLocation: {
          $set: false,
        },
      },
    }))
    this.validate()
  }

  setDeliveryMethod({ deliveryMethod, rateUid, carrierCode }: SetDeliveryMethodArgs): void {
    const data = _data()
    const hasSetInfo = deliveryMethod &&
      (data.deliveryInfo?.deliveryAction || !data.deliveryInfo?.requiresDeliveryAction) &&
      (data.deliveryInfo?.pargoLocation || !data.deliveryInfo?.requiresPargoLocation)
    _data(update(data, {
      deliveryInfo: {
        hasSetInfo: {
          $set: hasSetInfo,
        },
        deliveryMethod: {
          $set: deliveryMethod,
        },
        carrierCode: {
          $set: carrierCode,
        },
        requiresPargoLocation: {
          $set: carrierCode && carrierCode.indexOf('pargo') !== -1,
        },
        rateUid: {
          $set: rateUid,
        },
      },
    }))
    this.validate()
  }

  setDeliveryAction({ deliveryAction }: SetDeliveryActionArgs): void {
    const data = _data()
    const hasSetInfo = data?.deliveryInfo?.deliveryMethod &&
      (deliveryAction || !data.deliveryInfo?.requiresDeliveryAction) &&
      (data.deliveryInfo?.pargoLocation || !data.deliveryInfo?.requiresPargoLocation)
    _data(update(data, {
      deliveryInfo: {
        hasSetInfo: {
          $set: hasSetInfo,
        },
        deliveryAction: {
          $set: deliveryAction,
        },
      },
    }))
    this.validate()
  }

  setPargoLocation({ pargoLocation }: { pargoLocation: PargoLocationFragment | null }): void {
    const data = _data()
    const hasSetInfo = data?.deliveryInfo?.deliveryMethod &&
      (data.deliveryInfo?.deliveryAction || !data.deliveryInfo?.requiresDeliveryAction) &&
      (pargoLocation || !data.deliveryInfo?.requiresPargoLocation)
    _data(update(data, {
      deliveryInfo: {
        hasSetInfo: {
          $set: hasSetInfo,
        },
        pargoLocation: {
          $set: pargoLocation,
        },
      },
    }))
    this.validate()
  }

  setRequiresDeliveryAction({ requiresDeliveryAction }: SetRequiresDeliveryActionArgs): void {
    const data = _data()
    const hasSetInfo = data?.deliveryInfo?.deliveryMethod &&
      (data.deliveryInfo?.deliveryAction || !requiresDeliveryAction) &&
      (data.deliveryInfo?.pargoLocation || !data.deliveryInfo?.requiresPargoLocation)
    if (requiresDeliveryAction) {
      _data(update(data, {
        deliveryInfo: {
          hasSetInfo: {
            $set: hasSetInfo,
          },
          requiresDeliveryAction: {
            $set: requiresDeliveryAction,
          },
        },
      }))
    } else {
      _data(update(data, {
        deliveryInfo: {
          hasSetInfo: {
            $set: hasSetInfo,
          },
          requiresDeliveryAction: {
            $set: requiresDeliveryAction,
          },
          deliveryAction: {
            $set: ShippingActionEnum.NORMAL,
          },
        },
      }))
    }

    this.validate()
  }

  setPaymentMethod({ paymentMethod }: { paymentMethod: PaymentMethodInput }): void {
    try {
      let hasSetInfo = false
      if ([PaymentMethodEnum.checkmo, PaymentMethodEnum.ozow].includes(paymentMethod.code as PaymentMethodEnum)) {
        hasSetInfo = true
      } else if (paymentMethod?.peachpaymentsS2s?.token || paymentMethod?.peachpaymentsS2s?.number) {
        hasSetInfo = true
      }
      const newPaymentMethod: any = { ...PAYMENT_METHOD_DEFAULT_STATE, ...paymentMethod }
      if (paymentMethod.peachpaymentsS2s) {
        newPaymentMethod.peachpaymentsS2s = { ...DEFAULT_PEACH_PAYMENTS, ...paymentMethod.peachpaymentsS2s }
      }
      if (paymentMethod.ozow) {
        newPaymentMethod.ozow = { ...DEFAULT_OZOW, ...paymentMethod.ozow }
      }
      _data(update(_data(), {
        paymentInfo: {
          paymentMethod: {
            $set: newPaymentMethod,
          },
          hasSetInfo: {
            $set: hasSetInfo,
          },
        },
      }))
      this.validate()
    } catch (e) {
      console.log(e)
    }

  }

  setThreeDSecureId(threeDSecure: string): void {
    const data = _data()
    const paymentInfo = {
      ...data.paymentInfo,
      paymentMethod: {
        ...data.paymentInfo.paymentMethod,
        peachpaymentsS2s: {
          ...data.paymentInfo.paymentMethod.peachpaymentsS2s,
          threeDSecure,
        },
      },
      hasSetInfo: true,
    }
    _data(update(_data(), {
      paymentInfo: {
        $set: paymentInfo,
      },
    }))
    this.validate()
    this.nextPaymentStep()
  }

  resetPaymentInfo(): void {
    _data(update(_data(), {
      step: {
        $set: CheckoutStepEnum.PAYMENT,
      },
      paymentInfo: {
        $set: {
          ...PAYMENT_DEFAULT_STATE,
        },
      },
    }))
    this.validate()
  }

  setCanSkipThreeDSecure(canSkipThreeDSecure: boolean): void {
    _data(update(_data(), {
      paymentInfo: {
        hasSetInfo: {
          $set: true,
        },
        canSkipThreeDSecure: {
          $set: canSkipThreeDSecure,
        },
      },
    }))
    this.validate()
    this.nextPaymentStep()
  }

  validate(): void {
    const checkout = _data()
    let canAdvance = false
    if (checkout.step === CheckoutStepEnum.DELIVERY) {
      if (checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_ADDRESS && checkout.deliveryInfo.deliveryAddressId) {
        canAdvance = true
      }
      if (checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_METHOD &&
        checkout.deliveryInfo.deliveryMethod &&
        (checkout.deliveryInfo.deliveryAction || !checkout.deliveryInfo.requiresDeliveryAction)) {
        canAdvance = true
      }
    } else if (checkout.step === CheckoutStepEnum.PAYMENT) {
      if (checkout.paymentInfo.step === PaymentStepEnum.PAYMENT_METHOD && checkout.paymentInfo.paymentMethod) {
        if (checkout.paymentInfo?.paymentMethod?.code === PaymentMethodEnum.peachpayments_s2s) {
          if (checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.token || checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.number) {
            canAdvance = true
          }
        } else {
          canAdvance = true
        }
      } else if (checkout.paymentInfo.step === PaymentStepEnum.THREE_D_SECURE && checkout.paymentInfo.paymentMethod?.peachpaymentsS2s?.threeDSecure) {
        canAdvance = true
      }
    } else if (checkout.step === CheckoutStepEnum.PLACE) {
      // TODO:
    }
    _data(update(_data(), {
      canAdvance: {
        $set: canAdvance,
      },
    }))
  }

  nextDeliveryStep(): void {
    const checkout = _data()
    if (!checkout.canAdvance) {
      return
    }
    if (checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_ADDRESS) {
      _data(update(_data(), {
        deliveryInfo: {
          step: {
            $set: DeliveryStepEnum.DELIVERY_METHOD,
          },
        },
      }))
    } else if (checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_METHOD) {
      _data(update(_data(), {
        paymentInfo: {
          step: {
            $set: PaymentStepEnum.PAYMENT_METHOD,
          },
        },
      }))
    }
    this.validate()
  }

  nextPaymentStep(): void {
    const checkout = _data()
    if (!checkout.canAdvance) {
      return
    }
    if (checkout.paymentInfo.step === PaymentStepEnum.PAYMENT_METHOD) {
      if (checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.number && !checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.threeDSecure) {
        _data(update(_data(), {
          paymentInfo: {
            step: {
              $set: PaymentStepEnum.THREE_D_SECURE,
            },
          },
        }))
      } else {
        _data(update(_data(), {
          confirmInfo: {
            step: {
              $set: PlaceStepEnum.PLACE_ORDER,
            },
          },
        }))
      }
    } else {
      _data(update(_data(), {
        confirmInfo: {
          step: {
            $set: PlaceStepEnum.PLACE_ORDER,
          },
        },
      }))
    }
    this.validate()
  }

  getPaymentMethod(): any {
    const recurseDeleteTypeName = (data: any): any => {
      // Check if data is an array
      if (Array.isArray(data)) {
        return data.map(item => recurseDeleteTypeName(item))
      }
      // Check if data is an object
      else if (data !== null && typeof data === 'object') {
        // Create a new object to accumulate the cleaned properties
        const newObj: any = {}
        for (const [key, value] of Object.entries(data)) {
          // Remove 'id' and '__typename' keys
          if (key !== 'id' && key !== '__typename' && value !== null) {
            // Recursively clean the object
            newObj[key] = recurseDeleteTypeName(value)
          }
        }
        return newObj
      }
      // Return the data unchanged if it's not an object or array
      return data
    }
    const paymentMethod = { ..._data().paymentInfo.paymentMethod }
    return recurseDeleteTypeName(paymentMethod)
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    checkout: {
      read(): CheckoutFragment {
        return _data() as CheckoutFragment
      },
    },
  })

  queries = (): DocumentNode => gql`
    fragment LocalOzowPaymentInfoFragment on LocalOzowPaymentInfo {
      id
      cancelUrl
      successUrl
    }

    fragment LocalPeachPaymentsPaymentInfoFragment on LocalPeachPaymentsPaymentInfo {
      id
      brand
      cvv
      expiryMonth
      expiryYear
      name
      nickname
      number
      saveCard
      threeDSecure
      token
    }

    fragment LocalPaymentMethodInfoInputFragment on LocalPaymentMethodInfoInput {
      id
      code
      ozow {
        ...LocalOzowPaymentInfoFragment
      }
      peachpaymentsS2s {
        ...LocalPeachPaymentsPaymentInfoFragment
      }
    }

    fragment PargoLocationFragment on PargoLocation {
      address1
      address2
      addressSms
      businessHours
      city
      distance
      latitude
      longitude
      pargoPointCode
      phoneNumber
      photo
      postalcode
      province
      storeName
      storeOwner
      suburb
    }

    fragment DeliveryInfoFragment on DeliveryInfo {
      id
      step
      rateUid
      hasSetInfo
      deliveryMethod
      deliveryAction
      carrierCode
      requiresDeliveryAction
      requiresPargoLocation
      pargoLocation {
        ...PargoLocationFragment
      }
      deliveryAddressId
    }
    fragment PaymentInfoFragment on PaymentInfo {
      id
      step
      hasSetInfo
      canSkipThreeDSecure
      billingAddressId
      paymentMethod {
        ...LocalPaymentMethodInfoInputFragment
      }
    }
    fragment ConfirmInfoFragment on ConfirmInfo {
      id
      step
    }
    fragment CheckoutFragment on Checkout {
      id
      step
      canAdvance
      showSteps
      deliveryInfo {
        ...DeliveryInfoFragment
      }
      paymentInfo {
        ...PaymentInfoFragment
      }
      confirmInfo {
        ...ConfirmInfoFragment
      }
    }
    query GetCheckout {
      checkout @client {
        ...CheckoutFragment
      }
    }
  `

  types = (): DocumentNode => gql`
    enum CheckoutStepEnum {
      "Delivery"
      DELIVERY
      "Payment"
      PAYMENT
      "Place"
      PLACE
    }

    type PargoLocation {
      address1: String
      address2: String
      addressSms: String
      businessHours: String
      city: String
      distance: Float
      latitude: Float
      longitude: Float
      pargoPointCode: String
      phoneNumber: String
      photo: String
      postalcode: String
      province: String
      storeName: String
      storeOwner: String
      suburb: String
    }

    type DeliveryInfo {
      id: ID!
      step: DeliveryStepEnum!
      hasSetInfo: Boolean!
      rateUid: String
      deliveryAddressId: Int
      deliveryMethod: String
      carrierCode: String
      requiresPargoLocation: Boolean
      pargoLocation: PargoLocation
      requiresDeliveryAction: Boolean
    }

    enum PaymentMethodEnum {
      peachpayments_s2s
      ozow
      checkmo
    }

    enum PaymentTokeStatus {
      SAVED
      TEMP_LOCAL
    }

    enum DeliveryStepEnum {
      "Delivery Address"
      DELIVERY_ADDRESS
      "Delivery Method"
      DELIVERY_METHOD
    }

    enum PaymentStepEnum {
      "Payment Method"
      PAYMENT_METHOD
      "Three D Secure"
      THREE_D_SECURE
    }

    enum PlaceStepEnum {
      "Place Order"
      PLACE_ORDER
      "Confirm"
      CONFIRM
    }

    # NEW
    type LocalOzowPaymentInfo {
      id: ID!
      cancelUrl: String!
      successUrl: String!
    }

    type LocalPeachPaymentsPaymentInfo {
      id: ID!
      cvv: String
      expiryMonth: String
      expiryYear: String
      name: String
      nickname: String
      number: String
      saveCard: Boolean
      threeDSecure: String
      token: String
    }

    type LocalPaymentMethodInfoInput {
      id: ID!
      ozow: LocalOzowPaymentInfo
      peachpaymentsS2s: LocalPeachPaymentsPaymentInfo
    }
    # NEW


    type PaymentInfo {
      id: ID!
      step: PaymentStepEnum!
      hasSetInfo: Boolean!
      canSkipThreeDSecure: Boolean
      billingAddressId: Int
      paymentMethod: LocalPaymentMethodInfoInput
    }

    type ConfirmInfo {
      id: ID!
      step: PlaceStepEnum!
    }

    type Checkout {
      id: ID!
      step: CheckoutStepEnum!
      deliveryInfo: DeliveryInfo!
      paymentInfo: PaymentInfo!
      confirmInfo: ConfirmInfo!
      canAdvance: Boolean!
      showSteps: Boolean!
    }

    type AvailablePaymentMethod {
      id: ID!
    }

    type AvailableShippingMethod {
      id: ID!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type DeliveryInfo {
      deliveryAction: ShippingActionEnum
    }
    extend type LocalPaymentMethodInfoInput {
      code: PaymentMethodEnum
    }
    extend type LocalPeachPaymentsPaymentInfo {
      brand: PeachPaymentsCreditCardTypeEnum
    }
    extend type Query {
      checkout: Checkout!
    }
  `

}
