
import React from 'react'
import { Svg } from './Svg'

export interface ChatProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Chat({ color, hoverColor, className }: ChatProps): JSX.Element {

    return (
        <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 22.25C0 10.0997 9.84974 0.25 22 0.25H40C42.7614 0.25 45 2.48858 45 5.25V21.75C45 34.1764 34.9264 44.25 22.5 44.25H22C9.84973 44.25 0 34.4003 0 22.25Z" fill="#006654"/>
            <path d="M14 19.25C14 15.9363 16.6863 13.25 20 13.25H27C30.3137 13.25 33 15.9363 33 19.25V25.25C33 27.4591 31.2091 29.25 29 29.25H14V19.25Z" fill="white"/>
            <path d="M17 20.25H29" stroke="#006654" strokeWidth="2" strokeLinecap="round"/>
            <path d="M17 24.25H26" stroke="#006654" strokeWidth="2" strokeLinecap="round"/>
        </Svg>
    )

}
    