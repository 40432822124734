import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { AppPlugin } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Skeleton, SkeletonNode } from '@atoms/layout'
import { Divider } from '@atoms/layout/Divider'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PeachPaymentTokenFragment, useCustomerQuery, useRemovePeachPaymentsTokenMutation } from '@hooks/api/index'
import { useSimpleToasts } from '@simple/toasts'
import { Mutable } from '@uctypes/global'

import { CreditCardRow } from './CreditCardRow'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
    padding-bottom: 5.000vw;

    @media (min-width: 30em) {
      padding-bottom: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding-bottom: 1.111vw;
    }

    @media (min-width: 90em) {
      padding-bottom: 16px;
    }
  
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

const List = styled.div`
  
    padding: 0 0 5.000vw 0;

    @media (min-width: 30em) {
      padding: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0 1.111vw 1.111vw 1.111vw;
    }

    @media (min-width: 90em) {
      padding: 0 16px 16px 16px;
    }
  
`

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  
    width: CALC(100% -10.000vw);

    @media (min-width: 30em) {
      width: CALC(100% -10.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% -2.222vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% -32px);
    }
  
  
    margin: 5.000vw;

    @media (min-width: 30em) {
      margin: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw;
    }

    @media (min-width: 90em) {
      margin: 16px;
    }
  
  
    padding: 7.500vw;

    @media (min-width: 30em) {
      padding: 7.500vw;
    }

    @media (min-width: 30.0625em) {
      padding: 1.667vw;
    }

    @media (min-width: 90em) {
      padding: 24px;
    }
  
`

export interface CreditCardsProps {
  onAdd?: () => void
  selected?: string
  onSelect?: (id: string) => void
}

interface CreditCardsState {
  loadingRows: string[]
}

const DEFAULT_STATE: CreditCardsState = {
  loadingRows: [],
}

export function CreditCards({ onAdd, selected, onSelect }: CreditCardsProps): JSX.Element {

  const { data: customerData, loading: customerLoading, refetch } = useCustomerQuery({ variables: { pageSize: 100 } })
  const [removeCustomerCreditCard] = useRemovePeachPaymentsTokenMutation()
  const [state, setState] = useState<CreditCardsState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()

  const _handlRemove = async (id: string): Promise<void> => {
    setState((prevState) => update(prevState, {
      loadingRows: {
        $apply: (loadingRows) => [...loadingRows, id],
      },
    }))
    try {
      await removeCustomerCreditCard({
        variables: {
          id,
        },
      })
      addToast({
        message: 'Credit Card successfully removed',
        appearance: 'success',
      })
      refetch()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState((prevState) => update(prevState, {
        loadingRows: {
          $splice: [[prevState.loadingRows.indexOf(id), 1]],
        },
      }))
    }
  }

  const _handleSelect = (id: string): void => {
    onSelect(id)
  }

  const creditCards = [...customerData?.paymentTokens?.items || []] as Mutable<PeachPaymentTokenFragment[]>

  let creditCard!: PeachPaymentTokenFragment
  let creditCardIndex!: number

  const shouldSelect = onSelect !== undefined

  return (
    <Container>
      <Header>
        <Title variant='t3'>
          Saved Cards
        </Title>
        <If condition={!!onAdd}>
          <Button
            title='ADD NEW CARD'
            variant='ghost'
            size='medium'
            onClick={onAdd} />
        </If>
      </Header>
      <Choose>
        <When condition={customerLoading}>
          <Skeleton loop={2} direction='column' gap='10px'>
            <SkeletonNode
              color='pampas'
              height={{ mobile: '150px', tablet: '120px', desktop: '120px' }}/>
          </Skeleton>
        </When>
        <When condition={creditCards.length === 0}>
          <Empty>
            <Paragraph>
              No saved credit cards. You will be able to add a credit card during checkout.
            </Paragraph>
          </Empty>
        </When>
        <Otherwise>
          <List>
            <For each='creditCard' index='creditCardIndex' of={creditCards}>
              <CreditCardRow
                creditCard={creditCard}
                selected={creditCard.id === selected}
                key={creditCard.id}
                loading={state.loadingRows.includes(creditCard.id)}
                onRemove={() => _handlRemove(creditCard.id)}
                onSelect={shouldSelect ? () => _handleSelect(creditCard.id) : undefined} />
              <If condition={creditCardIndex < creditCards.length - 1}>
                <Divider />
              </If>
            </For>
          </List>
        </Otherwise>
      </Choose>
    </Container>
  )

}
