import React, { useEffect, useState } from 'react'

import { sentenceCase } from 'change-case'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Button } from '@atoms/buttons'
import { LayoutRow, LayoutCol, Card } from '@atoms/layout'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { CartFragment, useCustomerQuery, CheckoutFragment, useSetBillingAddressOnCartMutation } from '@hooks/api/index'
import { CheckoutInfo } from '@molecules/store/CheckoutInfo'
import { CheckoutSummary } from '@molecules/store/CheckoutSummary'
import { useSimpleToasts } from '@simple/toasts'
import { PaymentMethodEnum, PaymentStepEnum } from '@uctypes/api/globalTypes'

import { PaymentMethods } from './PaymentMethods'
import { SummaryModal } from './SummaryModal'
import { ThreeDSecure } from './ThreeDSecure'

const FixedMobile = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`

const FixedDesktop = styled.div`
  position: fixed;
  width: inherit;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    gap: 5.000vw;

    @media (min-width: 30em) {
      gap: 5.000vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.111vw;
    }

    @media (min-width: 90em) {
      gap: 16px;
    }
  
  width: 100%;
`

export interface PaymentProps {
  checkout: CheckoutFragment
  cart: CartFragment
  loading?: boolean
}

interface PaymentState {
  showConfirm: boolean
}

const DEFAULT_STATE: PaymentState = {
  showConfirm: false,
}

export function Payment({ checkout, cart }: PaymentProps): JSX.Element {

  const [state, setState] = useState<PaymentState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const [setBilingAddressOnCart, { loading: setBillingAddressLoading }] = useSetBillingAddressOnCartMutation()
  const { data: customerData } = useCustomerQuery()
  const navigate = useNavigate()
  const events = useEvents()

  const setBillingAddress = async (): Promise<void> => {
    if (checkout?.paymentInfo?.billingAddressId && checkout?.paymentInfo?.billingAddressId !== checkout?.deliveryInfo?.deliveryAddressId) {
      await setBilingAddressOnCart({
        variables: {
          input: {
            cartId: cart.id,
            billingAddress: {
              customerAddressId: checkout.paymentInfo.billingAddressId,
            },
          },
        },
      })
    } else {
      await setBilingAddressOnCart({
        variables: {
          input: {
            cartId: cart.id,
            billingAddress: {
              sameAsShipping: true,
            },
          },
        },
      })
    }
  }

  const _handleNext = async (): Promise<void> => {
    try {
      if (checkout.paymentInfo.step === PaymentStepEnum.PAYMENT_METHOD) {
        setState((prevState) => update(prevState, {
          showConfirm: {
            $set: true,
          },
        }))
      } else if (checkout.paymentInfo.step === PaymentStepEnum.THREE_D_SECURE) {
        events.hasAddedPaymentMethod(cart, 'Peach Payments')
        CheckoutPlugin.shared().nextPaymentStep()
        navigate('/checkout/place')
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }
  const _handleCancel = (): void => {
    setState((prevState) => update(prevState, {
      showConfirm: {
        $set: false,
      },
    }))
  }

  const _handleConfirm = async (): Promise<void> => {
    try {
      if (checkout.paymentInfo.step === PaymentStepEnum.PAYMENT_METHOD) {
        if (checkout.paymentInfo?.paymentMethod?.code === PaymentMethodEnum.ozow) {
          await setBillingAddress()
          events.hasAddedPaymentMethod(cart, 'Ozow')
          CheckoutPlugin.shared().nextPaymentStep()
          navigate('/checkout/place')
        } else if (checkout.paymentInfo?.paymentMethod?.code === PaymentMethodEnum.peachpayments_s2s) {
          if (checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.number) {
            setState((prevState) => update(prevState, {
              showConfirm: {
                $set: false,
              },
            }))
            await setBillingAddress()
            CheckoutPlugin.shared().nextPaymentStep()
          } else {
            await setBillingAddress()
            events.hasAddedPaymentMethod(cart, 'Peach Payments')
            CheckoutPlugin.shared().nextPaymentStep()
            navigate('/checkout/place')
          }
        } else {
          await setBillingAddress()
          events.hasAddedPaymentMethod(cart, sentenceCase(checkout.paymentInfo.paymentMethod.code))
          CheckoutPlugin.shared().nextPaymentStep()
          navigate('/checkout/place')
        }
      } else {
        CheckoutPlugin.shared().nextPaymentStep()
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  useEffect(() => {
    if (checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.threeDSecure || checkout.paymentInfo.canSkipThreeDSecure) {
      _handleNext()
    }
  }, [checkout.paymentInfo?.paymentMethod?.peachpaymentsS2s?.threeDSecure, checkout.paymentInfo.canSkipThreeDSecure])

  const loading = setBillingAddressLoading
  const hasProducts = cart?.items?.length > 0

  return (
    <>
      <SummaryModal cart={cart} checkout={checkout} onConfirm={_handleConfirm} onCancel={_handleCancel} open={state.showConfirm} loading={loading} />
      <LayoutRow>
        <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 8 }}>
          <Choose>
            <When condition={checkout.paymentInfo.step === PaymentStepEnum.PAYMENT_METHOD}>
              <PaymentMethods onContinue={_handleNext} />
            </When>
            <When condition={checkout.paymentInfo.step === PaymentStepEnum.THREE_D_SECURE}>
              <ThreeDSecure checkout={checkout} cart={cart} />
            </When>
          </Choose>
        </LayoutCol>
        <LayoutCol span={{ mobile: 0, tablet: 0, desktop: 4 }}>
          <If condition={!!cart}>
            <FixedDesktop>
              <InfoContainer>
                <CheckoutSummary
                  loading={loading}
                  cart={cart}
                  checkout={checkout}
                  onNext={_handleNext} />
                <CheckoutInfo
                  loading={loading}
                  cart={cart}
                  checkout={checkout} />
              </InfoContainer>
            </FixedDesktop>
          </If>
        </LayoutCol>
        <LayoutCol span={{ mobile: 10, tablet: 0, desktop: 0 }}>
          <FixedMobile>
            <Card>
              <Button
                title={hasProducts ? 'CONTINUE' : 'NO PRODUCTS'}
                size='medium'
                fullWidth
                loading={loading}
                disabled={!checkout.canAdvance || !hasProducts}
                onClick={_handleNext} />
            </Card>
          </FixedMobile>
        </LayoutCol>
      </LayoutRow>
    </>
  )

}
