import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Seperator } from '@atoms/layout'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { BreadcrumbFragment, ProductListFragment, useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { ProductCard } from '@molecules/store/ProductCard'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ leftPadding: boolean }>`
  width: 100%;
  position: relative;
  
    overflow: hidden;

    @media (min-width: 30em) {
      overflow: initial;
    }

    @media (min-width: 30.0625em) {
      overflow: initial;
    }

    @media (min-width: 90em) {
      overflow: initial;
    }
  
  ${ ({ leftPadding }) => leftPadding 
    ? ResponsivePXValue('padding', { mobile: '12px 0 12px 12px', tablet: '0 ', desktop: '0' })
    : ResponsivePXValue('padding', { mobile: '12px 0 12px 0', tablet: '0 ', desktop: '0' })}
  
    margin-top: initial;

    @media (min-width: 30em) {
      margin-top: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      margin-top: 0.556vw;
    }

    @media (min-width: 90em) {
      margin-top: 8px;
    }
  
  
  .mobile-all {
    
    margin: 5.000vw 0 0;

    @media (min-width: 30em) {
      margin: 5.000vw 0 0;
    }

    @media (min-width: 30.0625em) {
      margin: 1.111vw 0 0;
    }

    @media (min-width: 90em) {
      margin: 16px 0 0;
    }
  
    
    width: CALC(100% - 5.000vw);

    @media (min-width: 30em) {
      width: CALC(100% - 5.000vw);
    }

    @media (min-width: 30.0625em) {
      width: CALC(100% - 1.111vw);
    }

    @media (min-width: 90em) {
      width: CALC(100% - 16px);
    }
  
  }
`

const Spacer = styled.div`
  position: relative;
`

const Header = styled.div<{ sliderKey: number }>`
 
  display: flex;
  align-items: center;
  
    justify-content: flex-start;

    @media (min-width: 30em) {
      justify-content: space-between;
    }

    @media (min-width: 30.0625em) {
      justify-content: space-between;
    }

    @media (min-width: 90em) {
      justify-content: space-between;
    }
  
  
    max-height: 17.500vw;

    @media (min-width: 30em) {
      max-height: 17.500vw;
    }

    @media (min-width: 30.0625em) {
      max-height: 3.889vw;
    }

    @media (min-width: 90em) {
      max-height: 56px;
    }
  
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 0.556vw;
    }

    @media (min-width: 90em) {
      gap: 8px;
    }
  

  ${(props): string => ResponsivePXValue('margin', { mobile: '0 0 6px', tablet: '0 0 6px', desktop: props.sliderKey === 0 ? '0 0 16px 0' : '18px 0 16px 0' })}

  .seperator {
    flex-grow: 1;
    position: revert;
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }
  .filter-title {
    margin: 0;
    
    flex-shrink: initial;

    @media (min-width: 30em) {
      flex-shrink: initial;
    }

    @media (min-width: 30.0625em) {
      flex-shrink: 0;
    }

    @media (min-width: 90em) {
      flex-shrink: 0;
    }
  
  }
  .see-all {
    flex-shrink: 0;
    
    display: none;

    @media (min-width: 30em) {
      display: none;
    }

    @media (min-width: 30.0625em) {
      display: flex;
    }

    @media (min-width: 90em) {
      display: flex;
    }
  
  }
  
`

const Embla = styled.div`
  position: relative;
  
    overflow: visible;

    @media (min-width: 30em) {
      overflow: hidden;
    }

    @media (min-width: 30.0625em) {
      overflow: hidden;
    }

    @media (min-width: 90em) {
      overflow: hidden;
    }
  
  width: 100%;
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  
    gap: 2.500vw;

    @media (min-width: 30em) {
      gap: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      gap: 1.389vw;
    }

    @media (min-width: 90em) {
      gap: 20px;
    }
  
`

const EmblaSlide = styled.div<{ infiniteScroll: boolean }>`
  user-select: none;
  
    flex: 0 0 49.063vw;

    @media (min-width: 30em) {
      flex: 0 0 49.063vw;
    }

    @media (min-width: 30.0625em) {
      flex: 0 0 15.139vw;
    }

    @media (min-width: 90em) {
      flex: 0 0 218px;
    }
  

  ${props => props.infiniteScroll &&`
    &:last-child {
      
    padding-right: 2.500vw;

    @media (min-width: 30em) {
      padding-right: 2.500vw;
    }

    @media (min-width: 30.0625em) {
      padding-right: 1.389vw;
    }

    @media (min-width: 90em) {
      padding-right: 20px;
    }
  
  `}
`

const LeftControl = styled.div<{ visible: boolean, showCount: boolean }>`
  
    display: none;

    @media (min-width: 30em) {
      display: block;
    }

    @media (min-width: 30.0625em) {
      display: block;
    }

    @media (min-width: 90em) {
      display: block;
    }
  
  user-select: none;
  position: absolute;
  cursor: pointer;
  opacity: ${(props): number => props.visible ? 1 : 0};
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.2)};
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: CALC(50% - 4.688vw);

    @media (min-width: 30em) {
      top: CALC(50% - 4.688vw);
    }

    @media (min-width: 30.0625em) {
      top: CALC(50% - 1.042vw);
    }

    @media (min-width: 90em) {
      top: CALC(50% - 15px);
    }
  
  left: 0;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.4)};
  }
 
`

const RightControl = styled.div<{ visible: boolean, showCount: boolean }>`
  
    display: none;

    @media (min-width: 30em) {
      display: block;
    }

    @media (min-width: 30.0625em) {
      display: block;
    }

    @media (min-width: 90em) {
      display: block;
    }
  
  user-select: none;
  position: absolute;
  cursor: pointer;
  opacity: ${(props): number => props.visible ? 1 : 0};
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.2)};
  
    width: 8.750vw;

    @media (min-width: 30em) {
      width: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      width: 1.944vw;
    }

    @media (min-width: 90em) {
      width: 28px;
    }
  
  
    height: 8.750vw;

    @media (min-width: 30em) {
      height: 8.750vw;
    }

    @media (min-width: 30.0625em) {
      height: 1.944vw;
    }

    @media (min-width: 90em) {
      height: 28px;
    }
  
  
    border-radius: 6.250vw;

    @media (min-width: 30em) {
      border-radius: 6.250vw;
    }

    @media (min-width: 30.0625em) {
      border-radius: 1.389vw;
    }

    @media (min-width: 90em) {
      border-radius: 20px;
    }
  
  
    padding: 1.563vw;

    @media (min-width: 30em) {
      padding: 1.563vw;
    }

    @media (min-width: 30.0625em) {
      padding: 0.347vw;
    }

    @media (min-width: 90em) {
      padding: 5px;
    }
  
  
    top: CALC(50% - 4.688vw);

    @media (min-width: 30em) {
      top: CALC(50% - 4.688vw);
    }

    @media (min-width: 30.0625em) {
      top: CALC(50% - 1.042vw);
    }

    @media (min-width: 90em) {
      top: CALC(50% - 15px);
    }
  
  right: 0;

  &:hover {
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.4)};
  }
`

export interface ProductSliderProps {
  title?: string
  products: ProductListFragment[]
  total: number
  sliderKey?: number
  showCount?: boolean
  loading?: boolean
  basePath?: string
  onViewAll?: () => void
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  productsDiscount?: number | null
  forRecommendedProducts?: boolean
  infiniteScroll?: boolean
  lessThanSixItems?: boolean
  leftPadding?: boolean
}

interface ProductSliderState {
  previousDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  disaplyButtons: boolean
}

const DEFAULT_STATE: ProductSliderState = {
  previousDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  disaplyButtons: false,
}

export const ProductSlider = React.memo(
  function ProductSlider({
    title,
    products,
    total,
    loading,
    sliderKey = 0,
    basePath = '',
    onViewAll,
    showCount = true,
    overwriteBreadCrumbs,
    productsDiscount = null,
    infiniteScroll = false,
    lessThanSixItems = false,
    leftPadding = true,
  }: ProductSliderProps): JSX.Element {

    const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
    const [state, setState] = useState<ProductSliderState>({ ...DEFAULT_STATE })
    const [emblaRef, emblaApi] = useEmblaCarousel({
      align: 'start',
      dragFree: !!(appData.app.isNativeApp),
      slidesToScroll: 1,
      containScroll: 'trimSnaps',
      loop: !!infiniteScroll,
    })

    const _handleGoLeft = (): void => {
      if (emblaApi.canScrollPrev()) {
        emblaApi.scrollPrev()
      }
    }

    const _handleGoRight = (): void => {
      if (emblaApi.canScrollNext()) {
        emblaApi.scrollNext()
      } else {
        emblaApi.scrollTo(0)
      }
    }

    useEffect(() => {
      if (emblaApi) {
        emblaApi.on('select', () => {
          const previousDisabled = !emblaApi.canScrollPrev()
          const nextDisabled = false
          const currentIndex = emblaApi.selectedScrollSnap()
          setState((prevState) => update(prevState, {
            previousDisabled: { $set: previousDisabled },
            nextDisabled: { $set: nextDisabled },
            currentIndex: { $set: currentIndex },
          }))
        })
      }
    }, [emblaApi])

    let product: ProductListFragment

    const isLessThanSixItems = products.length <= 6

    return (
      <Container leftPadding={leftPadding}>
        <If condition={!!title || !!onViewAll}>
          <Header sliderKey={sliderKey}>
            <If condition={!!title}>
              <Heading variant='h5' className='filter-title' align='left'>
                {title}
              </Heading>
            </If>
            <If condition={showCount === true}>
              <Paragraph variant='p1' className='count' color={theme.colors.grey.silver}>({total})</Paragraph>
              <Seperator align='horizontal' className='seperator' color={theme.colors.grey.gallery} />
            </If>
            <If condition={!!onViewAll}>
              <Button
                disabled={loading}
                variant='primary'
                title='SEE ALL'
                size='medium'
                className='see-all'
                onClick={onViewAll} />
            </If>
          </Header>
        </If>
        <Spacer>
          <Embla ref={emblaRef}>
            <EmblaContainer>
              <For each='product' of={products}>
                <EmblaSlide
                  infiniteScroll={infiniteScroll}
                  key={product.id}>
                  <ProductCard basePath={basePath} key={product.id} product={product} disabled={loading} overwriteBreadCrumbs={overwriteBreadCrumbs} personalDiscount={productsDiscount || null}/>
                </EmblaSlide>
              </For>
            </EmblaContainer>
          </Embla>
          <If condition={!(lessThanSixItems && isLessThanSixItems)}>
            <LeftControl onClick={_handleGoLeft} visible={!state.previousDisabled} showCount={showCount} >
              <Icon
                icon={LocalIconEnums.CHEVRON_LEFT}
                color={theme.colors.green.bottleGreen} />
            </LeftControl>
            <RightControl onClick={_handleGoRight} visible={true} showCount={showCount} >
              <Icon
                icon={LocalIconEnums.CHEVRON_RIGHT}
                color={theme.colors.green.bottleGreen} />
            </RightControl>
          </If>
        </Spacer>
        <If condition={!!onViewAll}>
          <DeviceContainer mobile tablet>
            <Button
              variant='ghost'
              title='SEE ALL'
              size='medium'
              className='mobile-all'
              fullWidth
              onClick={onViewAll} />
          </DeviceContainer>
        </If>
      </Container>
    )

  },
)
